import React from 'react';
import { Helmet } from 'react-helmet';

import { Container, Grid, Footer } from './styles';

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>GMAD | Home</title>
        <meta
          name="description"
          content="G+ são iniciativas GMAD para apoiar ainda mais sua marcenaria. A maquininha é uma delas e o app para sempre estar na mão."
        />
      </Helmet>
      <Container>
        <img
          src={require('../../assets/img/man-laughing.jpg')}
          alt="Homem trabalhando feliz"
          className="working-man"
        />
        <img
          src={require('../../assets/img/mais-laranja.png')}
          alt="+ Laranja"
          className="mais-laranja"
        />

        <Grid>
          <div className="grid-content">
            <h1>
              Na <b>GMAD</b>
              <br />
              você sempre
              <br />
              pode mais!
            </h1>

            <h2>
              Com a GMAD você tem todas as vantagens para alavancar o seu
              negócio!
            </h2>

            <p>
              <strong>G+</strong> são iniciativas <strong>GMAD</strong> para
              apoiar ainda mais sua marcenaria.
              <br /> As <strong>soluções de pagamento G+</strong> são opções
              práticas e competitivas de parcelamento ao cliente com o
              recebimento do serviço à vista. Ao lado conheça as nossas soluções
            </p>

            <img
              src={require('../../assets/img/gmad-grupo-logo.svg')}
              alt="Logo GMAD Grupo Madcompen"
            />
          </div>

          <div className="grid-item">
            <img
              src={require('../../assets/img/mais-laranja.png')}
              alt="+ Laranja"
              className="mais-laranja"
            />
            <a href="/gmadplus" className="grid-card">
              <div className="grid-card-body">
                <img
                  src={require('../../assets/img/maquininha.png')}
                  alt="Maquininha G+"
                  className="gmadpay__app"
                />
                <img
                  src={require('../../assets/logo-dark.svg')}
                  alt="Logo G+"
                  className="logo"
                />
                <p>
                  A maquininha de cartão da GMAD. Parcele suas vendas com as
                  menores taxas e em mais vezes!
                </p>
                <a href="/gmadplus">SAIBA MAIS</a>
                <div className="bg" />
              </div>
            </a>
          </div>

          <div className="grid-item">
            <img
              src={require('../../assets/img/mais-detail.png')}
              alt="+ Verde"
              className="mais-verde"
            />
            <a href="/gmadpay" className="grid-card">
              <div className="grid-card-body">
                <img
                  src={require('../../assets/img/celular-app.png')}
                  alt="Celular app Gmadpay"
                  className="gmadpay-app"
                />
                <img
                  src={require('../../assets/gmadpay-logo.svg')}
                  alt="Logo Gmadpay"
                  className="gmadpay-logo"
                />
                <p>
                  Controle todos os seus recebimentos pelo nosso App totalmente
                  gratuito!
                </p>
                <a href="/gmadpay">SAIBA MAIS</a>
                <div className="bg" />
              </div>
            </a>
          </div>
        </Grid>
        <Footer>
          <p id="rights">GMAD © 2020 / Todos os direitos reservados</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://agenciaade.com.br/"
          >
            <p>
              Criado com{' '}
              <img src={require('../../assets/heart.svg')} alt="Coração" /> por
              agenciaade.com.br
            </p>
          </a>
        </Footer>
      </Container>
    </>
  );
}

export default LandingPage;
