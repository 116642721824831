import React, { useState } from 'react';

//styles
import { Navbar } from './style';
import Whatsapp from '../Whatsapp/index';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Whatsapp />
      <Navbar isOpen={isMenuOpen}>
        <a href="/gmadpay">
          <img src={require('../../assets/gmadpay-logo.svg')} alt="Logo G+" />
        </a>
        <div>
          <div className="btn-container">
            <div className="btn">
              <a href="/gmadpay#tenhointeresse">TENHO INTERESSE</a>
            </div>
            <div className="btn-secondary">
              <a href="/gmadplus">MAQUININHA G+</a>
            </div>
          </div>
          <button
            className={`
            hamburger hamburger--collapse
            ${isMenuOpen ? 'is-active' : ''}
          `}
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <nav>
            <ul>
              <li>
                <a href="/gmadpay">
                  <img
                    src={require('../../assets/gmadpay-logo.svg')}
                    alt="Logo Gmadpay"
                  />
                </a>
              </li>
              <li>
                <a
                  href="/gmadpay#porqueaderir"
                  onClick={() => setIsMenuOpen(false)}
                >
                  POR QUE ADERIR
                </a>
              </li>
              <li>
                <a
                  href="/gmadpay#comofunciona"
                  onClick={() => setIsMenuOpen(false)}
                >
                  COMO CONTRATAR
                </a>
              </li>
              <li>
                <a
                  href="/gmadpay#vantagens"
                  onClick={() => setIsMenuOpen(false)}
                >
                  VANTAGENS
                </a>
              </li>
              {/* <li>
              <a href="/gmadpay#dicas" onClick={() => setIsMenuOpen(false)}>
                DICAS
              </a>
            </li> */}
              {/* <li>
                <a
                  href="/gmadpay#simuladordetaxas"
                  onClick={() => setIsMenuOpen(false)}
                >
                  SIMULADOR DE TAXAS
                </a>
              </li> */}
              <li>
                <a
                  href="/gmadpay#perguntasfrequentes"
                  onClick={() => setIsMenuOpen(false)}
                >
                  PERGUNTAS FREQUENTES
                </a>
              </li>
              <li>
                <a
                  href="/gmadpay#tenhointeresse"
                  className="interesse"
                  onClick={() => setIsMenuOpen(false)}
                >
                  TENHO INTERESSE
                </a>
              </li>
              <li>
                <a
                  href="/gmadplus"
                  className="gmadplus"
                  onClick={() => setIsMenuOpen(false)}
                >
                  MAQUININHA G+
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Navbar>
    </>
  );
}
