import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

import 'antd/es/collapse/style/index.css';
import 'antd/es/notification/style/index.css';
import 'antd/es/select/style/index.css';
import 'antd/es/modal/style/index.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  .rodal-close:before, .rodal-close:after{
    background: #fff;
  }

  .rodal-close:hover:before, .rodal-close:hover:after{
    background: #fafafa;
  }
  
  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
  }

  .custom-notification{
    width: 600px !important;
  }

  .custom-notification span, .ant-notification-notice-message{
    font-size: 24px !important;
  }

  .ant-notification-notice-with-icon{
    display: flex;
    align-items: center;
  }

  .ant-notification-notice-content{
        height: 88px;
    display: flex;
  }

  h4 {
    font-size: 66px;
    line-height: 57px;
    font-weight: 300;
  }

  a, button {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 10px 35px;
    background-color: #e8540f;
    border-radius: 34px;       
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.5s;
    border: none;
    cursor: pointer;
    width: fit-content;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 20px;
    }

    :hover {
      background-color: #206730;
    }
  }
  
  .ant-notification-notice-close {
    background-color: transparent;
    padding: 0;

    :hover {
      background-color: transparent;
    }
  }

  .redborder {
    border: 2px solid #e8540f !important;
  }

  .hamburger {
    padding: 15px 0px 15px 20px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; 
    width: fit-content;
    z-index: 999;

    @media (max-width: 1286px) {
      display: inline-block;
    }
  }
    
  .hamburger:hover {
    opacity: 0.7; 
  }
    
  .hamburger.is-active:hover {
    opacity: 0.7; 
  }
    
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #fff; 
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; 
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; 
  }
  
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; 
  }
  
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; 
  }
  
  .hamburger-inner::before {
    top: -10px; 
  }
  
  .hamburger-inner::after {
    bottom: -10px; 
  }

  /*
   * Collapse
   */
  .hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
  }
  
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; 
  }
  
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
  }

  .hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
  }
  
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
  }
  
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
  }


  .ant-modal {
    width: 50% !important;
    height: fit-content;

    @media (max-width: 1100px) {
      width: 70% !important;
    }

    @media (max-width: 800px) {
      width: 100% !important;
    }
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0;
    position: relative;

    a {
      width: 100%;
      display: flex;
      background-color: transparent;
      border-radius: 0;
      padding: 0 20px;

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }

    button {
      position: absolute;
      top: -10px;
      right: 0px;
      padding: 0;
      width: 50px;
      height: 50px;
      
      @media (max-width: 800px) {
        width: 40px;
        height: 40px;
      }

      img {
        height: 20px;

        @media (max-width: 800px) {
          height: 15px;
        }
      }
    }
  }
`;
