import styled from 'styled-components';

const Whats = styled.div`
  a {
    background: #206730;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    padding: 10px;
    border-radius: 50px;

    @media (min-width: 768px) {
      padding: 20px;
      bottom: 20px;
      right: 40px;
    }
  }
`;

export { Whats };
