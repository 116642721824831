import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const CookieHandle = styled.div`
  .CookieConsent {
    div {
      display: flex;

      a {
        background-color: transparent;
        padding: 0 5px;
        font-size: 16px;
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  background: #206730;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
  }

  .mais-laranja {
    display: none;
  }

  @media (max-width: 900px) {
    img {
      right: -150px;

      @media (max-width: 600px) {
        right: -70px;
      }
    }

    .mais-laranja {
      display: initial;
    }

    .working-man {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  width: 90%;
  height: 100vh;
  min-height: 92vh;

  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 12px;

  align-items: center;
  justify-items: center;

  padding: 40px 50px;

  .grid-content {
    color: #fff;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
    padding-left: 0;
    padding-right: 30%;

    h1 {
      width: 100%;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 41px;
      font-weight: 300;
      color: white;
    }

    h2 {
      position: relative;
      padding-bottom: 20px;
      font-weight: 300;
      color: white;

      &::after {
        content: '';
        width: 50%;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    p {
      margin: 20px 0px;
      text-align: justify;
      font-size: 17px;
    }

    img {
      position: relative;
      top: unset;
      right: unset;
      width: 40%;
      margin: 30px 0px;
    }
  }

  .grid-item {
    position: relative;
    height: 100%;

    .mais-laranja {
      display: initial;
      position: absolute;
      object-fit: contain;
      width: 100%;
      top: 0;
      left: -12vw;
      z-index: 0;
    }

    .mais-verde {
      position: absolute;
      object-fit: contain;
      width: 100%;
      top: unset;
      bottom: -20px;
      right: -12vw;
      z-index: 0;
    }
  }

  .grid-card {
    position: relative;
    width: 100%;
    height: 90%;
    padding: 0px;
    padding-top: 0;
    margin: 0;
    border-radius: 0;

    color: #000;
    font-weight: 300;
    background: transparent;
    font-size: 14px;

    .bg {
      position: absolute;
      bottom: 0;
      align-self: flex-end;
      background: #fff;
      height: 75%;
      width: 100%;
      content: '';
    }

    .grid-card-body {
      width: 100%;
      height: 90%;
      padding: 30px 0px;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      /* background: #fff; */
    }

    img {
      position: relative;
      object-fit: contain;
      width: 70%;
      top: -50px;
      z-index: 5;
    }

    .gmadpay-app {
      position: relative;
      object-fit: contain;
      width: 80%;
      height: 300px;
      top: -85px;
      z-index: 5;
    }
    .gmadpay__app {
      position: relative;
      object-fit: contain;
      width: 80%;
      height: 300px;
      top: -65px;
      z-index: 5;
    }

    p {
      text-align: center;
      z-index: 5;
      font-size: 17px;
    }

    .logo {
      width: 30%;
      position: relative;
      top: -30px;
      z-index: 5;
    }

    .gmadpay-logo {
      width: 80%;
      position: relative;
      top: -40px;
      z-index: 5;
    }

    a {
      margin-top: 0px;
      opacity: 0;
      transition: all ease 0.3s;
      z-index: 5;
    }

    &:hover {
      a {
        margin-top: 12px;
        background: #e8540f;
        opacity: 1;
        transition: all ease 0.3s;
      }
    }
  }

  @media (max-width: 900px) {
    height: auto;

    grid-template-columns: 1fr;
    grid-gap: 70px;
    padding: 40px 20px;
    z-index: 0;

    .grid-content {
      padding: 40px 0;
      padding-bottom: 0;
    }

    .grid-item {
      .mais-laranja {
        width: 50%;
        top: 60px;
        left: -70px;
      }

      .mais-verde {
        width: 50%;
        bottom: -60px;
        right: -70px;
      }
    }

    .grid-card {
      height: fit-content;

      img {
        display: initial;
        right: -15px;
      }

      .bg {
        align-self: center;
      }

      .grid-card-body {
        padding: 20px;
        align-items: center;
        justify-content: center;

        a {
          margin-top: 8px;
          opacity: 1;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 30px 20px;
  background-color: #fff;
  z-index: 5;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 20px;
  }

  a {
    padding: 0;
    background-color: #fff;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    color: #4e4e4e;
    width: fit-content;
    line-height: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }

  img {
    display: initial;
    right: 0;
    position: relative;
    width: fit-content;
    margin: 0 3px;
    height: 15px;

    animation-name: ${heartBeat};
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
`;
