import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (max-width: 850px) {
    height: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
    align-items: center;
  }

  .logo {
    width: 20%;
    padding: 80px 20px 50px 20px;
    background-color: #246732;
    margin-left: 5.5%;

    @media (max-width: 1440px) {
      padding: 60px 20px 40px 20px;
      width: 18%;
    }

    @media (max-width: 1050px) {
      width: 25%;
    }

    @media (max-width: 850px) {
      align-self: flex-start;
      padding: 50px 20px 20px 20px;
    }

    @media (max-width: 650px) {
      width: 35%;
    }

    @media (max-width: 430px) {
      padding: 30px 20px 20px 20px;
      width: 40%;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    padding-left: 8%;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0;

    @media (max-width: 1440px) {
      width: 67%;
    }

    @media (max-width: 1050px) {
      width: 90%;
    }

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    }

    @media (max-width: 650px) {
      width: 100%;
    }

    .text1 {
      width: 50%;

      @media (max-width: 850px) {
        width: 100%;
        margin-bottom: 50px;
        max-width: 475px;
      }

      @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: unset;
      }

      .text {
        object-fit: contain;
        width: 100%;

        @media (max-width: 650px) {
          max-width: 470px;
          align-self: center;
        }
      }

      .content-phone {
        display: none;

        @media (max-width: 650px) {
          display: inline;
          position: relative;
          right: -20px;
          margin-top: 35px;
          width: 70%;
        }

        @media (max-width: 430px) {
          width: 80%;
        }
      }
    }

    .baixar {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 850px) {
        width: 100%;
        max-width: 400px;
      }

      > img {
        width: 62%;
      }

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        a {
          padding: 0;
          background-color: transparent;
          width: 47%;

          img {
            object-fit: contain;
            width: 100%;
          }

          :hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  .footer {
    position: relative;
    width: 100%;

    .text {
      padding-left: 8%;
      margin: 35px 0;
      width: 47%;

      @media (max-width: 1050px) {
        width: 60%;
      }

      @media (max-width: 650px) {
        display: none;
      }
    }

    > div {
      position: relative;
      height: 24vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 31%;
      padding-top: 40px;

      @media (max-width: 650px) {
        padding-right: 0;
      }

      @media (max-width: 430px) {
        padding-top: 20px;
      }

      .bg {
        position: absolute;
        bottom: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .logo-footer {
        position: relative;
        z-index: 1;
      }
    }
  }

  .phone {
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 30%;

    @media (max-width: 1440px) {
      width: 31%;
      bottom: -30px;
    }

    @media (max-width: 1050px) {
      width: 32%;
    }

    @media (max-width: 650px) {
      display: none;
    }
  }
`;
