import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './style';

export default function Downloads() {
  return (
    <>
      <Helmet>
        <title>Download GMAD Pay | Maquininha G+</title>
        <meta
          name="description"
          content="G+ são iniciativas GMAD para apoiar ainda mais sua marcenaria. A maquininha é uma delas. Uma opção prática e competitiva de parcelamento a seu cliente e o recebimento do seu serviço à vista."
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Container>
        <div className="logo">
          <img src={require('../../assets/img/gmadplay.png')} alt="Gmad Pay" />
        </div>
        <div className="content">
          <div className="text1">
            <img
              className="text"
              src={require('../../assets/img/facilidade.svg')}
              alt="MUITO MAIS FACILIDADE PARA VOCÊ"
            />
            <img
              className="content-phone"
              src={require('../../assets/img/phone-mobile.png')}
              alt="App"
            />
          </div>
          <div className="baixar">
            <img
              src={require('../../assets/img/baixeagora.svg')}
              alt="BAIXE AGORA"
            />
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.acanto.gmad"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/googleplay.jpg')}
                  alt="Google Play"
                />
              </a>
              <a
                href="https://apps.apple.com/br/app/gmad-pay/id1544762799"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/applestore.jpg')}
                  alt="Apple Store"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer">
          <img
            className="text"
            src={require('../../assets/img/contato.svg')}
            alt="ENTRE EM CONTATO COM O SEU VENDEDOR GMAD PARA SABER COMO ACESSAR!"
          />
          <div>
            <img
              className="bg"
              src={require('../../assets/img/footer-gmadpay.jpg')}
              alt=""
            />
            <img
              className="logo-footer"
              src={require('../../assets/img/gmad-logobranca.svg')}
              alt=""
            />
          </div>
        </div>
        <img
          className="phone"
          src={require('../../assets/img/phone.png')}
          alt="App"
        />
      </Container>
    </>
  );
}
