import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Content = styled.section`
  width: 100%;
  padding: 160px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1170px) {
    padding: 100px 20px 0px 20px;
  }

  .nodisplay {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  .Loading {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      box-sizing: border-box;
      border: solid 10px rgba(255, 255, 255, 0.2);
      border-top-color: #206730;
      animation: spin 1s infinite linear;
      margin-bottom: 180px;

      @media (max-width: 750px) {
        margin-bottom: 80px;
      }
    }
  }

  .imagem-principal {
    width: 850px;
    height: 850px;

    @media (max-width: 890px) {
      width: 100%;
      height: auto;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    max-width: 850px;

    @media (max-width: 890px) {
      max-width: 100%;
    }

    h4 {
      font-size: 40px;
      line-height: 55px;
      color: #206730;
      margin-top: 30px;
      margin-bottom: 40px;

      @media (max-width: 750px) {
        font-size: 27px;
        line-height: 42px;
      }
    }

    .data {
      font-size: 20px;
      color: #206730;
      display: flex;
      align-items: center;

      @media (max-width: 750px) {
        font-size: 18px;
      }

      svg {
        width: 25px;
        height: auto;
        margin-right: 10px;
      }
    }

    p,
    li {
      font-size: 18px;
      line-height: 32px;
      color: #2f2f2f;
      margin: 30px 0;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 30px;
        margin: 20px 0;
      }
    }

    ul {
      margin-left: 50px;

      @media (max-width: 750px) {
        margin-left: 30px;
      }

      li {
        margin: 0;
      }
    }
  }

  a {
    padding: 0;
    background-color: #fff;
    color: #206730;

    :hover {
      color: #e8540f;
      background-color: #fff;
    }
  }

  .mais {
    background-color: #e8540f;
    padding: 10px 35px;
    color: #fff;
    :hover {
      color: #fff;
      background-color: #206730;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-row-gap: 180px;
    grid-column-gap: 50px;
    margin-bottom: 180px;

    @media (max-width: 1190px) {
      grid-template-columns: repeat(3, 300px);
    }

    @media (max-width: 1040px) {
      grid-template-columns: repeat(3, 280px);
      grid-column-gap: 30px;
    }

    @media (max-width: 940px) {
      grid-template-columns: repeat(2, 280px);
      grid-column-gap: 30px;
    }

    @media (max-width: 750px) {
      margin-bottom: 80px;
    }

    @media (max-width: 630px) {
      grid-template-columns: repeat(1, 280px);
      grid-row-gap: 50px;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .imagem {
        width: 100%;
        height: 250px;
        margin-bottom: 20px;

        @media (max-width: 1040px) {
          height: 200px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .date {
        font-size: 16px;
        color: #2e2e2e;

        @media (max-width: 750px) {
          font-size: 14px;
        }
      }

      .title {
        font-size: 31px;
        line-height: 36px;
        color: #2e2e2e;
        margin: 25px 0 30px 0;
        font-weight: bold;

        @media (max-width: 750px) {
          font-size: 24px;
          line-height: 29px;
        }
      }

      p {
        font-size: 20px;
        line-height: 24px;
        color: #2e2e2e;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      a {
        margin-top: 50px;
        padding: 10px 0;
        width: 100%;
      }
    }
  }

  h4 {
    font-size: 40px;
    line-height: 55px;
    color: #206730;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-top: 100px;

    @media (max-width: 750px) {
      font-size: 27px;
      line-height: 42px;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .circle {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-sizing: border-box;
    border: solid 10px rgba(255, 255, 255, 0.2);
    border-top-color: #206730;
    animation: spin 1s infinite linear;
    margin-bottom: 180px;

    @media (max-width: 750px) {
      margin-bottom: 80px;
    }
  }

  button {
    text-align: center;
    cursor: pointer;
    border: none;
    margin-bottom: 100px;

    @media (max-width: 750px) {
      margin-bottom: 70px;
    }
  }

  #bottom-footer {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 30px 0px 45px 0px;
    background-color: #fff;

    @media (max-width: 650px) {
      flex-direction: column;
    }

    a {
      padding: 0;
      background-color: #fff;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: #4e4e4e;
      width: fit-content;
      line-height: 20px;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
    }

    img {
      margin: 0 3px;
      height: 15px;
      width: unset;

      animation-name: ${heartBeat};
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  }
`;
