import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Gmadplus from '../pages/Gmadplus';
import Gmadpay from '../pages/Gmadpay';
import Blog from '../pages/Blog';
import Downloads from '../pages/Downloads';
import LandingPage from '../pages/LandingPage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/gmadplus" exact component={Gmadplus} />
      <Route path="/gmadpay" exact component={Gmadpay} />
      <Route path="/blog/:slug" exact component={Blog} />
      <Route path="/downloads" exact component={Downloads} />
    </Switch>
  );
}
