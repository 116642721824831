import styled from 'styled-components';

export const Navbar = styled.header`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 6%;
  position: fixed;
  backdrop-filter: blur(5px);
  z-index: 99;

  @media (max-width: 1440px) {
    padding: 20px 3%;
  }

  @media (max-width: 1325px) {
    padding: 20px;
  }

  @media (max-width: 1080px) {
    padding: 10px 20px;
  }

  a {
    background-color: transparent;
    padding: 0;
    font-weight: 400;

    img {
      @media (max-width: 1080px) {
        width: 90%;
      }
    }

    :hover {
      background-color: transparent;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 1080px) {
      flex-direction: row;
      align-items: center;
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 20px;
      border-bottom: 1px solid #206730;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    .btn {
      @media (max-width: 1080px) {
        display: none;
      }

      a {
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        background-color: #e8540f;
        border-radius: 34px;
        padding: 10px 35px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-left: auto;

        :hover {
          background-color: #fff;
          color: #e8540f;
        }
      }
    }

    .btn-secondary {
      margin-left: 16px;

      @media (max-width: 1080px) {
        display: none;
      }

      a {
        color: #206730;
        background-color: transparent;
        border: solid 1px #206730;
        font-size: 15px;
        font-weight: bold;
        border-radius: 34px;
        padding: 10px 35px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin-left: auto;
        transition: all ease 0.3s;

        :hover {
          background-color: #206730;
          color: #fff;
          transition: all ease 0.3s;
        }
      }
    }

    .hamburger {
      padding: 0;
      width: 50px;
      height: 50px;

      @media (min-width: 1080px) {
        display: none;
      }
    }

    .hamburger-inner {
      background-color: #000;

      &:before {
        background-color: #000;
      }

      &:after {
        background-color: #000;
      }
    }

    .hamburger.is-active .hamburger-inner {
      background-color: #000;

      &:before {
        background-color: #000;
      }

      &:after {
        background-color: #000;
      }
    }

    nav {
      padding-top: 20px;
      transition: all ease 0.5s;

      @media (max-width: 1080px) {
        position: fixed;
        top: 0;
        background-color: rgba(245, 245, 245, 0.97);
        width: 100%;
        height: 100vh;
        right: ${(props) => (props.isOpen ? '0px' : '-100%')};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
      }

      ul {
        display: flex;
        list-style: none;

        @media (max-width: 1080px) {
          flex-direction: column;
          position: relative;
        }

        li {
          padding: 0 17.5px;

          @media (max-width: 1080px) {
            padding: 10px 0;
            text-align: center;
          }

          a {
            text-decoration: none;
            color: #206730;
            font-size: 16px;
            transition: all ease 0.5s;

            @media (max-width: 1080px) {
              margin: auto;
            }

            :hover {
              color: #e8540f;
            }
          }

          .interesse {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
            background-color: #e8540f;
            border-radius: 34px;
            padding: 10px 35px;
            text-decoration: none;
            display: flex;
            width: fit-content;
            margin-left: auto;
            display: none;

            @media (max-width: 1080px) {
              display: inline;
            }

            :hover {
              background-color: #fff;
            }
          }

          .gmadplus {
            color: #206730;
            border: solid 1px #206730;
            font-size: 15px;
            font-weight: bold;
            background-color: transparent;
            border-radius: 34px;
            padding: 10px 35px;
            text-decoration: none;
            display: flex;
            width: fit-content;
            margin-left: auto;
            display: none;

            @media (max-width: 1080px) {
              display: inline;
            }

            :hover {
              background-color: #206730;
              color: #fff;
            }
          }
        }

        li:first-child {
          display: none;

          @media (max-width: 1080px) {
            display: inline;
          }
        }

        li:nth-child(2) {
          padding-left: 0;

          @media (max-width: 1080px) {
            padding-top: 20px;
          }
        }

        li:last-child {
          padding: 0;

          @media (max-width: 1080px) {
            padding: 20px 0;
          }
        }

        li:nth-last-child(2) {
          padding-right: 0;
        }
      }
    }
  }
`;
