import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #bottom-footer {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 30px 20px 45px 20px;
    background-color: #fff;

    @media (max-width: 650px) {
      flex-direction: column;
    }

    a {
      padding: 0;
      background-color: #fff;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      color: #4e4e4e;
      width: fit-content;
      line-height: 20px;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
    }

    img {
      margin: 0 3px;
      height: 15px;

      animation-name: ${heartBeat};
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  }
`;

export const Banner = styled.section`
  background-color: #fff;
  width: 100%;
  padding: 195px 20px 120px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
  /* margin-bottom: 80px; */

  @media (max-width: 1170px) {
    padding: 100px 20px 60px 20px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 550px) {
    margin-bottom: 0;
  }

  .texto {
    width: 30%;

    @media (max-width: 1170px) {
      width: 35%;
    }

    @media (max-width: 850px) {
      margin-bottom: 50px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 630px) {
      width: 100%;
    }

    h2 {
      font-size: 36px;
      font-weight: 300;
      color: #206730;

      strong {
        color: #e45412;
      }
    }

    p {
      font-size: 18px;
      color: #000;
      line-height: 30px;
      margin-top: 50px;
      font-weight: 300;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 34px;
      }
    }
  }

  .imagem {
    width: 35%;
    height: fit-content;
    position: relative;

    @media (max-width: 1170px) {
      width: 50%;
    }

    @media (max-width: 850px) {
      width: 100%;
      display: flex;
      justify-content: center;
      max-height: 375px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;

      @media (max-width: 850px) {
        width: 405px;
        margin: auto;
      }

      @media (max-width: 445px) {
        width: 100%;
      }
    }
  }
`;

export const Porque = styled.section`
  width: 100%;
  height: fit-content;
  margin-top: -145px;
  padding-top: 300px;
  position: relative;
  background: #f7f7f7;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1170px) {
    margin-top: -100px;
    padding-top: 200px;
  }

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }

  .porque {
    display: flex;
    background: #fff;
    position: relative;
    width: 80%;
    margin-bottom: 145px;
    padding: 40px;

    @media (max-width: 600px) {
      width: 90%;
      flex-direction: column-reverse;
      padding: 0px;
      margin-bottom: 0;
    }

    .bg-img {
      width: 50%;
      height: auto;
      position: absolute;

      top: -30vh;
      left: 30vw;

      @media (max-width: 1080px) {
        width: 50%;
        top: 5vh;
      }

      @media (max-width: 600px) {
        width: 100%;
        top: -30vh;
        left: 30vw;
        z-index: 0;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    h4 {
      color: #206730;
      margin-bottom: 35px;
      width: 30%;

      font-size: 34px;
      line-height: 38px;

      @media (max-width: 1015px) {
        padding: 0;
      }

      @media (max-width: 750px) {
        font-size: 26px;
        line-height: 30px;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 20px;
        padding-bottom: 40px;
        margin-bottom: 0;
        z-index: 5;
      }

      strong {
        color: #e8530e;
      }
    }

    a {
      align-self: flex-start;
      margin-left: 6%;
      font-size: 25px;
      text-align: center;

      @media (max-width: 1285px) {
        margin: 0 20px;
      }

      @media (max-width: 1015px) {
        margin: 0;
      }

      @media (max-width: 800px) {
        font-size: 20px;
      }

      @media (max-width: 670px) {
        font-size: 16px;
      }

      @media (max-width: 345px) {
        font-size: 14px;
      }
    }
  }

  #detail {
    position: absolute;
    left: -212px;
    bottom: -199px;
    z-index: 1;

    @media (max-width: 559px) {
      display: none;
    }
  }
`;

export const Funciona = styled.section`
  background-color: #206730;
  padding: 95px 10px 155px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 750px) {
    padding-bottom: 80px;
  }

  h4 {
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 155px;
    margin-top: -155px;
    font-size: 34px;
    line-height: 28px;

    @media (max-width: 750px) {
      font-size: 34px;
      line-height: 28px;
    }
  }

  p {
    color: #fff;
    margin-bottom: 60px;

    @media (max-width: 600px) {
      text-align: center;
      font-size: 14px;
    }
  }

  .cards {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    /* grid-auto-rows: 225px; */

    @media (max-width: 1340px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1130px) {
      grid-template-columns: repeat(4, 1fr);
      /* grid-auto-rows: 250px; */
    }

    @media (max-width: 980px) {
      grid-template-columns: repeat(4, 1fr);
      /* grid-auto-rows: 190px; */
    }

    @media (max-width: 830px) {
      grid-template-columns: repeat(4, 1fr);
      /* grid-auto-rows: 210px; */
    }

    @media (max-width: 680px) {
      display: none;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      position: relative;
      padding: 40px;
      height: 100%;
      min-height: fit-content;

      @media (max-width: 980px) {
        padding: 30px;
      }

      p {
        font-size: 16px;
        line-height: 18px;
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: center;

        @media (max-width: 980px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .next {
        position: absolute;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8540f;
        border-radius: 100%;
        right: -50px;
        z-index: 1;

        @media (max-width: 980px) {
          width: 50px;
          height: 50px;
          right: -35px;

          img {
            width: 25px;
            height: auto;
          }
        }
      }

      .bottom {
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        bottom: -50px;
        z-index: 1;

        @media (max-width: 980px) {
          width: 50px;
          height: 50px;
          bottom: -35px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    div:nth-child(4) {
      .next {
        right: unset;
        bottom: -50px;

        @media (max-width: 980px) {
          bottom: -35px;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(5) {
      .next {
        img {
          transform: rotate(180deg);
        }
      }
    }

    div:nth-child(6) {
      .next {
        img {
          transform: rotate(180deg);
        }
      }
    }

    div:nth-child(7) {
      .next {
        img {
          transform: rotate(180deg);
        }
      }
    }

    div:nth-child(8) {
      .next {
        img {
          transform: rotate(180deg);
        }
      }
    }

    div:nth-child(9) {
      background-color: #e8540f;
      border: 1px solid #e8540f;
      grid-area: 3 / 1 / span 1 / span 2;

      .bottom {
        bottom: unset;
        right: -50px;

        @media (max-width: 980px) {
          right: -35px;
        }

        img {
          transform: rotate(-90deg);
        }
      }
    }

    div:nth-child(10) {
      background-color: #e8540f;
      border: 1px solid #e8540f;

      .bottom {
        bottom: unset;
        right: -50px;

        @media (max-width: 980px) {
          right: -35px;
        }

        img {
          transform: rotate(-90deg);
        }
      }
    }

    div:nth-child(11) {
      background-color: #e8540f;
      border: 1px solid #e8540f;

      .bottom {
        bottom: unset;
        right: -50px;

        @media (max-width: 980px) {
          right: -35px;
        }

        img {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .cardsresizedfor2 {
    display: none;
    grid-template-columns: 1fr;
    /* grid-auto-rows: 190px; */
    grid-gap: 20px;
    width: 90%;
    margin: 0 20px;

    @media (max-width: 680px) {
      display: grid;
    }

    @media (max-width: 560px) {
      grid-template-columns: 1fr;
      /* grid-auto-rows: 210px; */
    }

    @media (max-width: 440px) {
      width: 100%;
      grid-template-columns: 1fr;
      grid-auto-rows: minmax(fit-content, 210px);
      grid-gap: 10px;
    }

    @media (max-width: 340px) {
      grid-template-columns: 1fr;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      position: relative;
      padding: 30px;
      width: 100%;
      min-height: 80px;

      @media (max-width: 440px) {
        padding: 15px;
      }

      p {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        color: #fff;
        text-align: center;
      }

      .next {
        position: absolute;
        width: 50px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8540f;
        border-radius: 100%;
        right: -35px;
        z-index: 1;

        @media (max-width: 440px) {
          width: 40px;
          height: 40px;
          right: -15%;
        }

        img {
          width: 25px;
          height: auto;

          @media (max-width: 440px) {
            width: 20px;
          }
        }
      }

      .bottom {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        bottom: -35px;
        z-index: 1;

        @media (max-width: 440px) {
          width: 40px;
          height: 40px;
          bottom: -15%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    div:nth-child(1) {
      height: 100px;

      .next {
        right: unset;
        bottom: -30px;
        padding: 0;

        @media (max-width: 440px) {
          bottom: -20%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(2) {
      padding: 30px 10px;

      .next {
        right: unset;
        bottom: -35px;
        padding: 0;

        @media (max-width: 440px) {
          bottom: -20%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(3) {
      padding: 30px 10px;

      .next {
        right: unset;
        bottom: -35px;
        padding: 0px;

        @media (max-width: 440px) {
          bottom: -30%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(4) {
      padding: 30px 10px;
      /* padding: 60px 15px; */

      .next {
        right: unset;
        bottom: -15px;
        padding: 0px;

        @media (max-width: 440px) {
          bottom: -15%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(5) {
      height: 80px;

      .next {
        right: unset;
        bottom: -35px;
        padding: 0px;

        @media (max-width: 440px) {
          bottom: -30%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(6) {
      height: 80px;

      .next {
        right: unset;
        bottom: -35px;
        padding: 0px;

        @media (max-width: 440px) {
          bottom: -30%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(7) {
      padding: 30px 15px;

      .next {
        right: unset;
        bottom: -35px;
        padding: 0px;

        @media (max-width: 440px) {
          bottom: -30%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(8) {
      padding: 15px;
      height: 100px;

      .next {
        right: unset;
        top: -35px;
        padding: 0px;

        @media (max-width: 440px) {
          top: -30%;
        }

        img {
          transform: rotate(90deg);
        }
      }
    }

    div:nth-child(9) {
      background-color: #e8540f;
      border: 1px solid #e8540f;
      /* grid-area: 6 / 1 / span 1 / span 2; */
      height: 170px;
    }

    div:nth-child(10) {
      background-color: #e8540f;
      border: 1px solid #e8540f;
      height: 170px;
      /* grid-area: 7 / 1 / span 1 / span 2; */

      .bottom {
        right: unset;
        top: -35px;
        height: 40px;
        padding: 0;

        @media (max-width: 440px) {
          top: -15%;
        }
      }
    }

    div:nth-child(11) {
      background-color: #e8540f;
      border: 1px solid #e8540f;
      height: 170px;
      /* grid-area: 8 / 1 / span 1 / span 2; */

      .bottom {
        right: unset;
        top: -35px;
        height: 40px;
        padding: 0;

        @media (max-width: 440px) {
          top: -15%;
        }
      }
    }
  }

  .small-warning {
    align-self: flex-end;
    padding-top: 16px;
    padding-right: 5vw;
    color: #fff;
    font-size: 12px;
    z-index: 5;

    @media (max-width: 600px) {
      padding-top: 8px;
      padding-right: 5vw;
      margin-bottom: 0;
      text-align: left;
    }
  }

  #detail {
    position: absolute;
    width: 30vw;
    right: -15vw;
    bottom: -30vh;

    @media (max-width: 750px) {
      display: none;
    }
  }
`;

export const LinkApps = styled.section`
  padding: 250px 0px 100px 0px;
  margin-top: -150px;
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;

  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 150px;
  }

  .texto {
    width: 40%;
    padding-top: 150px;

    @media (max-width: 1170px) {
      width: 45%;
    }
    
    @media (max-width: 767px) {
      text-align: center;
    }

    @media (max-width: 850px) {
      margin-bottom: 50px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 630px) {
      width: 100%;
      padding-top: 80px;
      display: flex;
      align-items: flex-start;
    }

    h2 {
      font-size: 40px;
      line-height: 42px;
      font-weight: 300;
      color: #000;
      width: 70%;

      @media (max-width: 600px) {
        width: 100%;
        font-size: 36px;
        line-height: 37px;
      }

      strong {
      }
    }

    h4 {
      font-size: 26px;
      line-height: 28px;
      padding-top: 30px;
      font-weight: 300;
    }

    p {
      font-size: 18px;
      color: #000;
      line-height: 30px;
      margin-top: 8px;
      padding-bottom: 12px;
      font-weight: 300;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 34px;
      }
    }

    .app-links {
      padding-top: 30px;
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
        align-self: center;
      }

      a {
        background: transparent;
        padding: 0;
        margin-right: 50px;

        @media (max-width: 600px) {
          margin-right: 0px;
          margin-top: 30px;
        }

        img {
          object-fit: contain;
          object-position: center;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .imagem {
    width: 45%;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: end;

    @media (max-width: 1170px) {
      width: 50%;
    }

    @media (max-width: 850px) {
      width: 100%;
      display: flex;
      justify-content: center;
      max-height: 375px;
    }

    img {
      object-fit: cover;
      width: 50%;
      height: 60%;

      @media (max-width: 850px) {
        width: 405px;
        margin: auto;
      }

      @media (max-width: 445px) {
        width: 80%;
      }
    }
  }
`;

export const Vantagem = styled.section`
  padding: 0px 0px 240px 0px;
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;

  img {
    width: 50%;
  }

  @media (max-width: 650px) {
    flex-direction: column-reverse;
    padding: 0px 0px 80px 0px;

    img {
      width: 100%;
    }
  }

  .texto {
    width: 40%;
    padding: 0 20px;

    @media (max-width: 1170px) {
      width: 45%;
    }

    @media (max-width: 850px) {
      margin-bottom: 50px;
      margin-top: 50px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 630px) {
      width: 100%;
      padding: 0;
    }

    h4 {
      font-size: 48px;
      line-height: 42px;
      padding-top: 30px;
      font-weight: 300;
    }

    p {
      font-size: 24px;
      color: #000;
      line-height: 110%;
      padding-top: 20px;
      font-weight: 300;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 34px;
      }
    }
  }
`;

export const Videos = styled.section`
  width: 100%;
  padding: 80px 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;

    @media (max-width: 900px) {
      padding: 0;
    }

    @media (max-width: 1024px) {
      padding: 0;
    }

    svg {
      transition: all ease 0.3s;
      color: #e45412;

      @media (max-width: 900px) {
        font-size: 24px;
      }
    }

    &:hover {
      background: transparent;

      svg {
        opacity: 0.5;
        transition: all ease 0.3s;
      }
    }
  }

  .carousel-container {
    width: fit-content;
    display: flex;

    @media (max-width: 900px) {
      width: 80%;
    }
  }

  .ant-carousel,
  .slick-slide,
  .ant-carousel,
  .slick-slider,
  .slick-track,
  .slick-list,
  .slick-active,
  .slick-current {
    width: 560px;
    height: 100%;
    display: inline-block;
    > div {
      height: 100%;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  .slick-slide {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-current {
    z-index: 10;
  }

  .container-iframe {
    /* background: red; */
    /* width: fit-content !important; */
    /* height: 315px; */

    display: flex !important;
    flex: column;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;

    iframe {
      z-index: 0;
      transition: all ease 0.3s;
      width: 672px;
      height: 378px;

      @media (max-width: 900px) {
        width: 100%;
        height: 300px;
      }

      &:hover {
        transform: scale(1.05);
        transition: all ease 0.3s;
      }
    }
  }
`;

// export const Dicas = styled.section`
//   padding: 195px 20px 145px 20px;
//   background-color: #f5f5f5;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   margin-top: -140px;

//   @media (max-width: 1170px) {
//     padding: 130px 20px 145px 20px;
//     margin-top: -80px;
//   }

//   h4 {
//     color: #2f2f2f;
//     margin-bottom: 85px;

//     @media (max-width: 750px) {
//       font-size: 34px;
//       line-height: 28px;
//       margin-bottom: 50px;
//     }
//   }

//   .cards {
//     display: grid;
//     grid-template-columns: repeat(3, 350px);
//     grid-row-gap: 180px;
//     grid-column-gap: 50px;
//     margin-bottom: 180px;

//     @media (max-width: 1190px) {
//       grid-template-columns: repeat(3, 300px);
//     }

//     @media (max-width: 1040px) {
//       grid-template-columns: repeat(3, 280px);
//       grid-column-gap: 30px;
//     }

//     @media (max-width: 940px) {
//       grid-template-columns: repeat(2, 280px);
//       grid-column-gap: 30px;
//     }

//     @media (max-width: 750px) {
//       margin-bottom: 80px;
//     }

//     @media (max-width: 630px) {
//       grid-template-columns: repeat(1, 280px);
//       grid-row-gap: 50px;
//     }

//     .card {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       align-items: center;
//       background-color: transparent;
//       padding: 0;

//       :hover {
//         transform: scale(1.05);

//         button {
//           background-color: #206730;
//         }
//       }

//       .imagem {
//         width: 100%;
//         height: 250px;
//         margin-bottom: 20px;

//         @media (max-width: 1040px) {
//           height: 200px;
//         }

//         img {
//           object-fit: cover;
//           width: 100%;
//           height: 100%;
//         }
//       }

//       .date {
//         font-size: 16px;
//         color: #2e2e2e;

//         @media (max-width: 750px) {
//           font-size: 14px;
//         }
//       }

//       .title {
//         font-size: 31px;
//         line-height: 36px;
//         color: #2e2e2e;
//         margin: 25px 0 30px 0;
//         font-weight: bold;

//         @media (max-width: 750px) {
//           font-size: 24px;
//           line-height: 29px;
//         }
//       }

//       p {
//         font-size: 18px;
//         line-height: 32px;
//         color: #2e2e2e;
//         font-weight: 300;

//         @media (max-width: 750px) {
//           font-size: 16px;
//           line-height: 30px;
//         }
//       }

//       button {
//         margin-top: 50px;
//         padding: 10px 0;
//         width: 100%;
//       }
//     }
//   }

//   @keyframes spin {
//     100% {
//       transform: rotate(360deg);
//     }
//   }

//   .circle {
//     width: 80px;
//     height: 80px;
//     border-radius: 40px;
//     box-sizing: border-box;
//     border: solid 10px rgba(255, 255, 255, 0.2);
//     border-top-color: #206730;
//     animation: spin 1s infinite linear;
//     margin-bottom: 180px;

//     @media (max-width: 750px) {
//       margin-bottom: 80px;
//     }
//   }

//   button {
//     text-align: center;
//     cursor: pointer;
//     border: none;
//   }
// `;

export const Simulador = styled.section`
  padding: 220px 20px 130px 20px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 140px;

  @media (max-width: 1170px) {
    padding: 100px 20px 60px 20px;
  }

  h4 {
    color: #2f2f2f;
    width: fit-content;
    margin: auto;

    @media (max-width: 750px) {
      font-size: 34px;
      line-height: 34px;
      text-align: center;
    }
  }

  .firstsbox {
    margin-top: 80px;
    display: flex;
    justify-content: center;

    @media (max-width: 985px) {
      flex-direction: column;
    }

    @media (max-width: 750px) {
      margin-top: 50px;
    }

    > div {
      display: flex;
      flex-direction: column;

      .inputfield {
        display: flex;
        align-items: center;

        @media (max-width: 985px) {
          margin: 10px 0;
        }
      }
    }

    span {
      font-size: 16px;
      color: #e8540f;
      font-weight: bold;

      @media (max-width: 750px) {
        font-size: 14px;
      }
    }

    label {
      font-size: 22px;
      line-height: 23px;
      color: #2f2f2f;
      margin-right: 20px;

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 19px;
      }
    }

    input,
    .ant-select {
      border: 1px solid #707070;
      border-radius: 34px;
      height: 60px;
      padding-left: 20px;
      font-size: 20px;
      line-height: 21px;
      color: #2f2f2f;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 17px;
      }
    }

    input[type='text'] {
      width: 285px;
      margin-right: 20px;

      @media (max-width: 1120px) {
        width: 200px;
      }

      @media (max-width: 985px) {
        width: 285px;
        margin: 0;
      }

      @media (max-width: 490px) {
        width: 50%;
      }

      @media (max-width: 325px) {
        width: 45%;
      }
    }

    .ant-select {
      width: 150px;
      padding-left: 0;

      @media (max-width: 1120px) {
        width: 100px;
      }

      @media (max-width: 985px) {
        width: 150px;
      }

      @media (max-width: 375px) {
        width: 35%;
      }

      @media (max-width: 325px) {
        width: 30%;
      }

      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .ant-select-selector {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }

    .ant-select-selection-item {
      font-size: 20px;
      line-height: 21px;
      color: #2f2f2f;
      font-weight: 400;
    }

    .ant-select-arrow {
      width: 20px;
      height: 20px;
      top: 30px;

      svg {
        color: #2f2f2f;
      }
    }

    button {
      margin-left: 50px;
      height: 60px;

      @media (max-width: 985px) {
        margin-left: 0px;
        margin-top: 10px;
        width: fit-content;
      }
    }
  }

  fieldset {
    padding: 30px 55px 40px 55px;
    background-color: #e8540f;
    border: none;
    width: 85%;
    margin: 65px auto 0 auto;

    @media (max-width: 1080px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      padding: 30px;
    }

    input:disabled {
      background-color: #fff !important;
    }

    .firsthalf {
      display: flex;
      flex-direction: column;
      padding-bottom: 35px;
      border-bottom: 1px solid #ffffff;

      > div {
        display: grid;
        grid-template-columns: 30.5% 20% 23.5% 20%;
        grid-column-gap: 20px;
        margin: 25px 0;

        @media (max-width: 930px) {
          grid-template-columns: 50% 47%;
          margin: 10px 0;
        }

        @media (max-width: 450px) {
          grid-template-columns: 100%;
          margin: 10px 0;
        }

        label {
          font-size: 20px;
          line-height: 21px;
          color: #fff;
          display: flex;
          align-items: center;

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 19px;
          }
        }

        .resultado {
          background-color: #fff;
          width: 100%;
          height: 60px;
          border-radius: 34px;
          display: flex;
          align-items: center;

          @media (max-width: 930px) {
            margin: 10px 0;
          }

          input {
            padding-left: 20px;
            font-size: 20px;
            line-height: 21px;
            color: #2f2f2f;
            border: none;
            border-radius: 34px;
            width: 100%;
            z-index: 1;

            :disabled {
              background-color: #fff !important;
              color: #2f2f2f !important;
              opacity: 2 !important;
            }

            @media (max-width: 750px) {
              font-size: 16px;
              line-height: 17px;
              color: #000;
            }
          }
        }
      }

      div:nth-child(2) {
        .simuladorinfos {
          grid-area: 1 / 1 / span 1 / span 4;

          @media (max-width: 930px) {
            grid-area: 1 / 1 / span 1 / span 2;
          }

          @media (max-width: 450px) {
            grid-area: 1 / 1;
          }

          p {
            font-size: 18px;
            line-height: 21px;
            /* color: #e8540f; */
            color: white;
            font-weight: bold;
            /* background-color: #fff; */
            padding: 5px 4px;
            margin: 0;

            @media (max-width: 450px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }
    }

    .secondhalf {
      padding-top: 55px;

      @media (max-width: 750px) {
        padding-top: 30px;
      }

      .title {
        font-size: 25px;
        line-height: 26px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 45px;

        @media (max-width: 750px) {
          font-size: 22px;
          line-height: 23px;
          margin-bottom: 25px;
        }

        @media (max-width: 450px) {
          font-size: 20px;
          line-height: 21px;
        }
      }

      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        @media (max-width: 930px) {
          flex-direction: column;
        }

        @media (max-width: 750px) {
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          width: 50%;

          @media (max-width: 930px) {
            width: 100%;
            margin: 10px 0;
          }

          @media (max-width: 450px) {
            flex-direction: column;
          }
        }

        label {
          font-size: 20px;
          line-height: 21px;
          color: #fff;
          display: flex;
          align-items: center;
          margin-right: 20px;
          max-width: 305px;

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 19px;
          }
        }

        .resultado {
          background-color: #fff;
          width: 40%;
          height: 60px;
          border-radius: 34px;
          display: flex;
          align-items: center;

          @media (max-width: 500px) {
            width: 53%;
          }

          @media (max-width: 450px) {
            margin-top: 10px;
            width: 100%;
          }

          input {
            border-radius: 34px;
            border: none;
            padding-left: 20px;
            font-size: 20px;
            line-height: 21px;
            color: #2f2f2f;
            width: 100%;

            @media (max-width: 750px) {
              font-size: 16px;
              line-height: 17px;
            }
            :disabled {
              background-color: #fff !important;
              color: #2f2f2f !important;
              opacity: 2 !important;
            }
          }
        }
      }

      p {
        font-size: 15px;
        line-height: 17px;
        color: #fff;
      }
    }
  }
`;

export const Perguntas = styled.section`
  padding: 200px 20px 210px 20px;
  background-color: #ebebeb;
  width: 100%;
  margin-top: -140px;
  margin-bottom: 75px;

  @media (max-width: 1170px) {
    padding: 140px 20px 120px 20px;
    margin-top: -80px;
  }

  @media (max-width: 750px) {
    margin-bottom: 40px;
  }

  h4 {
    width: fit-content;
    margin: auto;
    margin-bottom: 90px;

    @media (max-width: 750px) {
      font-size: 34px;
      line-height: 34px;
      text-align: center;
      margin-bottom: 50px;
    }
  }

  a {
    padding: 0;
    background-color: transparent;
    background: none;
    color: inherit;
  }

  .ant-collapse {
    width: 80%;
    margin: auto;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .ant-collapse,
  .ant-collapse-item {
    border: none;
    background-color: transparent;
    border-radius: 34px !important;
  }

  .ant-collapse-item {
    margin: 20px 0;
  }

  .ant-collapse-header {
    border: 1px solid #206730;
    border-radius: 34px !important;
    background-color: #fff;
    color: #2f2f2f !important;
    font-size: 20px;
    font-weight: bold;
    line-height: 36px;
    padding: 15px 30px !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 32px;
    }

    ::before,
    ::after {
      display: none !important;
    }

    .ant-collapse-extra {
      height: 20px;
      width: 40px;
      position: relative;
      right: 0;

      svg {
        width: 100%;
        height: auto;
        object-fit: cover;
        fill: #206730;
        top: -10px;
        position: relative;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background-color: #206730 !important;
      color: #fff !important;

      svg {
        fill: #fff;
        transform: rotate(180deg);
      }
    }
  }

  .ant-collapse-content {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-content-box {
    padding: 30px;
    p {
      font-size: 20px;
      color: #2f2f2f;
      line-height: 36px;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 32px;
      }
    }
  }

  .duvidas {
    width: 80%;
    margin: auto;
    margin-top: 70px;
    padding: 125px 40px;
    background-color: #e8540f;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    border-radius: 20px;

    .d1 {
      gap: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-bottom: 30px;

      h4 {
        width: 100%;
      }
      @media (min-width: 1023px){
        text-align: start;
      }
    }

    .d2 {
      width: 100%;
      display: flex;
      width: 100%;
      justify-content: space-between;

      div {
        width: 50%;
      }

      div:last-child {
        margin-left: 30px;
        border-left: 1px solid white;
        padding-left: 30px;
      }
    
      p {
        width: 100%;
        max-width: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      a {
        display: flex;
        align-items: center;
        margin: 10px 0;

        :hover {
          color: white;
        }

        svg {
          margin-right: 10px;
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        text-align: center;

        div {
          width: 100%;
        }  

        div:last-child{
          border:none;
          padding:0;
          margin-left:0;
        }

        p {
          display: flex;
          flex-direction: column;
          align-items : center;
        }

        a {
          font-size: 14px;
          background: transparent;
        } 
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
    }


    @media (max-width: 750px) {
      padding: 50px 20px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 50px;

      .d1 {
        flex-direction: column;
        align-items: center;
      }
    }

    h4 {
      color: #fff;
      margin: 0;
      width: fit-content;

      @media (max-width: 750px) {
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 20px;
      color: #fff;
      line-height: 25px;
      max-width: 465px;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
`;

export const Interesse = styled.section`
  padding: 200px 20px 40px 20px;
  background-color: #206730;
  width: 100%;
  margin-top: -140px;

  @media (max-width: 1170px) {
    padding: 100px 20px 40px 20px;
    margin-top: -80px;
  }

  h4 {
    color: #fff;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 34px;
      line-height: 34px;
      text-align: center;
    }
  }

  .title {
    margin-bottom: 40px;
    font-size: 35px;
    color: #fff;
    padding-left: 10px;

    @media (max-width: 750px) {
      font-size: 28px;
    }
  }

  label,
  p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    padding-left: 10px;

    @media (max-width: 750px) {
      font-size: 18px;
    }
  }

  p {
    margin-top: 30px;
    margin-bottom: 0px;

    @media (max-width: 750px) {
      font-size: 16px;
    }
  }

  input,
  .ant-select {
    font-size: 16px;
    color: #2f2f2f;
    padding: 0 20px;
    height: 60px;
    background-color: #fff;
    border: none;
    border: 1px solid #707070;
    border-radius: 34px;
    position: relative;

    :focus {
      border: 1px solid #e8540f;
    }
  }

  .select-loja{
    font-size: 16px;
    color: #2f2f2f;
    padding: 0 20px;
    height: 60px;
    background-color: #fff;
    border: none;
    border: 1px solid #707070;
    border-radius: 34px;

    :focus {
      border: 1px solid #e8540f;
    }
  }
  
  .ant-select {
    padding: 0;
  }

  .ant-select-selector {
    height: 100% !important;
    border: none !important;
    border-radius: 34px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px !important;
  }

  .ant-select-selection-item {
    font-size: 16px;
    color: #2f2f2f;
    font-weight: unset;
  }

  #numero-span {
    bottom: -20px;

    @media (max-width: 1512px) {
      bottom: -35px;
    }

    @media (max-width: 930px) {
      bottom: -20px;
    }

    @media (max-width: 609px) {
      bottom: -30px;
    }

    @media (max-width: 600px) {
      bottom: -20px;
    }
  }

  span {
    color: #e8540f;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    bottom: -20px;

    svg {
      width: 18px;
      height: 18px;
      position: absolute;
      top: -2px;
      margin-left: 5px;
      right: -20px;
    }
  }

  form {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 40px;

    @media (max-width: 1000px) {
      width: 100%;
    }

    fieldset {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: none;
      padding-bottom: 65px;
      padding-top: 25px;
      border-bottom: 1px solid #fff;

      .cadastro {
        display: grid;
        grid-template-columns: repeat(2, 48%);
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }
      }

      .marcenaria {
        display: grid;
        grid-template-columns: repeat(3, 20%) 33%;
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 880px) {
          grid-template-columns: repeat(2, 48%);
        }

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }
      }

      .proprietario1 {
        display: grid;
        grid-template-columns: 35% 14% 14% 30%;
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 930px) {
          grid-template-columns: repeat(3, 30%);
        }

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }

        div:first-child {
          @media (max-width: 930px) {
            grid-area: 1 / 1 / span 1 / span 2;
          }

          @media (max-width: 600px) {
            grid-area: unset;
          }
        }

        div:last-child {
          @media (max-width: 930px) {
            grid-area: 2 / 2 / span 1 / span 2;
          }

          @media (max-width: 600px) {
            grid-area: unset;
          }
        }
      }

      .proprietario2 {
        display: grid;
        grid-template-columns: 16% 16% 30.5% 30.5%;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        margin-top: 30px;

        @media (max-width: 930px) {
          grid-template-columns: repeat(2, 48%);
        }

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }
      }

      .endereco1 {
        display: grid;
        grid-template-columns: 22% 74%;
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }
      }

      .endereco2 {
        display: grid;
        grid-template-columns: 10% 34% 40% 10%;
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        margin-top: 30px;

        @media (max-width: 930px) {
          grid-template-columns: 22% 50% 22%;
        }

        @media (max-width: 600px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
        }

        div:nth-child(2) {
          @media (max-width: 930px) {
            grid-area: 1 / 2 / span 1 / span 2;
          }

          @media (max-width: 600px) {
            grid-area: unset;
          }
        }

        div:nth-last-child(2) {
          @media (max-width: 930px) {
            grid-area: 2 / 1 / span 1 / span 2;
          }

          @media (max-width: 600px) {
            grid-area: unset;
          }
        }
      }

      .bancario {
        display: grid;
        grid-template-columns: 33% 15% 22% 200px;
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 930px) {
          grid-template-columns: repeat(2, 48%);
        }

        @media (max-width: 930px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 5px 0;
            height: 30px;
            position: relative;

            @media (max-width: 930px) {
              justify-content: flex-start;
            }

            label {
              margin-bottom: 0;
              padding-left: 0;
            }

            .whitecircle {
              width: 30px;
              height: 30px;
              background-color: #fff;
              border-radius: 100%;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-left: 20px;

              .orangecircle {
                width: 20px;
                height: 20px;
                background-color: #e8540f;
                border: 1px solid #e8540f;
                border-radius: 100%;
              }
            }
          }
        }
      }
    }

    .banco {
      border-bottom: none;

      .title {
        flex-direction: row;
        display: flex;
        align-items: center;

        @media (max-width: 620px) {
          flex-direction: column;
          align-items: flex-start;
        }

        p {
          font-size: 35px;
          color: #fff;
          padding-left: 10px;
          margin: 0;
          margin-bottom: 15px;

          @media (max-width: 750px) {
            font-size: 28px;
          }
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0 5px 70px;
          height: 30px;
          position: relative;

          @media (max-width: 930px) {
            justify-content: flex-start;
          }

          align-items: center;

          @media (max-width: 620px) {
            margin: 5px 0;
          }

          label {
            margin-bottom: 0;
            padding-left: 0;
          }

          .whitecircle {
            width: 30px;
            height: 30px;
            background-color: #fff;
            border-radius: 100%;
            border: 1px solid #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;

            .orangecircle {
              width: 20px;
              height: 20px;
              background-color: #e8540f;
              border: 1px solid #e8540f;
              border-radius: 100%;
            }
          }
        }
      }
    }

    .documentos {
      border-top: 1px solid #fff;
      border-bottom: none;

      > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 30px;

        @media (max-width: 700px) {
          grid-template-columns: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          position: relative;

          .anexar-btn {
            font-size: 16px;
            color: #2f2f2f;
            padding: 0 20px;
            height: 60px;
            background-color: #fff;
            border: none;
            border: 1px solid #707070;
            border-radius: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0;
            > svg {
              margin-right: 15px;
            }
          }

          .fileName p {
            margin-top: 0px;
            font-weight: bold;
          }
        }
      }

      .campos-obrigatorios {
        margin-top: 50px;
      }
    }

    .sendbtn {
      position: relative;

      .disabledButton {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0%;
        z-index: 1;
      }

      button {
        margin-top: 20px;

        @media (max-width: 750px) {
          margin: 0;
        }
      }
    }
  }
`;
