/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
// import ReactHtmlParser from 'react-html-parser';
import CurrencyInput from 'react-currency-input';
import { Collapse, notification, Select, Modal, Carousel } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
// import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

import {
  Container,
  Banner,
  Porque,
  Funciona,
  Credenciamento,
  // Dicas,
  Videos,
  Simulador,
  Perguntas,
  Interesse,
} from './styles';

// components
import Header from '../../components/Header/Header';

// assets
import arrow from '../../assets/img/arrow.svg';

// api
import api from '../../services/api';
import cep from '../../services/cep';
import cnpj from '../../services/cnpj';
import ademail from '../../services/ademail';
import { LinkApps } from '../Gmadpay/styles';

export default function Home() {
  const [modalOpen, setModalOpen] = useState(false);
  const [dicas, setDicas] = useState();
  const [numerodeposts, setNumerodeposts] = useState(6);
  const [loading, setLoading] = useState(true);
  const [valordavenda, setValordavenda] = useState(0);
  const [parcelas, setParcelas] = useState('0');
  const [valorporparcela, setValorporparcela] = useState();
  const [bandeira, setBandeira] = useState('0');
  const [valorLiquido, setValorLiquido] = useState();
  const [valornaconta, setValornaconta] = useState();
  const [valordecreditos, setValordecreditos] = useState();
  const [valorcomtaxa, setValorcomtaxa] = useState();
  const [valorparcelacomtaxa, setValorparcelacomtaxa] = useState();
  const [erroValor, setErroValor] = useState('');
  const [tipodaconta, setTipodaConta] = useState('Conta Corrente');
  const [pep, setPep] = useState('');
  const genExtra = () => <IoIosArrowDown />;
  const { Panel } = Collapse;
  const { Option } = Select;
  const [endereco, setEndereco] = useState({
    cep: '',
    rua: '',
    bairro: '',
    cidade: '',
    uf: '',
  });
  const [empresa, setEmpresa] = useState({
    cnpj: '',
    razaosocial: '',
    nomefantasia: '',
  });
  const [numCPF, setNumCPF] = useState();
  const [validoCPF, setValidoCPF] = useState();
  const [errocep, setErroCep] = useState();
  const [errorua, setErroRua] = useState();
  const [errobairro, setErrobairro] = useState();
  const [errocidade, setErroCidade] = useState();
  const [errouf, setErroUf] = useState();
  const [errocnpj, setErroCnpj] = useState();
  const [disabledButton, setDisabledButton] = useState(false);

  const videosRef = useRef();
  const carouselVideos = {
    adaptiveHeight: false,
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    autoplay: false,
    autoplaySpeed: 1000,
  };

  useEffect(() => {
    setModalOpen(true);

    api
      .get(`posts?_embed&per_page=${numerodeposts}&categories=1612`)
      .then((response) => {
        setDicas(response.data);
        setLoading(false);
      });
  }, [numerodeposts]);

  let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
  CurrencyInput.prototype.componentDidMount = function () {
    this.theInput.setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = (start, end) => {
      if (document.activeElement === this.theInput) {
        this.theInput.setSelectionRange_super(start, end);
      }
    };
    componentDidMount_super.call(this, ...arguments);
  };

  function CalcularTaxas() {
    if (valordavenda === 0 || valordavenda === 'R$0,00') {
      setErroValor('Valor inválido!');
      return;
    }
    let valorcomponto = valordavenda.replace('.', '');
    let valorcomR = valorcomponto.replace(',', '.');
    let valor = Number(valorcomR.substr(2));

    const bandeiras = [
      {
        0: 1.25,
        1: 2.57,
        2: 4.99,
        3: 5.84,
        4: 6.69,
        5: 7.53,
        6: 8.36,
        7: 9.18,
        8: 9.99,
        9: 10.78,
        10: 11.57,
        11: 12.36,
        12: 13.13,
        13: 13.89,
        14: 14.65,
        15: 15.39,
        16: 16.13,
        17: 16.86,
        18: 17.58,
      },
      {
        0: 1,
        1: 3.12,
        2: 5.49,
        3: 6.34,
        4: 7.19,
        5: 8.03,
        6: 8.86,
        7: 9.68,
        8: 10.49,
        9: 11.28,
        10: 12.07,
        11: 12.86,
        12: 13.63,
        13: 14.39,
        14: 15.15,
        15: 15.89,
        16: 16.63,
        17: 17.36,
        18: 18.08,
      },
      {
        0: 1.4,
        1: 3.32,
        2: 5.49,
        3: 6.34,
        4: 7.19,
        5: 8.03,
        6: 8.86,
        7: 9.68,
        8: 10.49,
        9: 11.28,
        10: 12.07,
        11: 12.86,
        12: 13.63,
        13: 14.39,
        14: 15.15,
        15: 15.89,
        16: 16.63,
        17: 17.36,
        18: 18.08,
      },
    ];

    // bandeiras[bandeira][parcelas]
    let p = bandeiras[parseInt(bandeira)][parseInt(parcelas)] / 100;

    if (parseInt(parcelas) === 0) setParcelas('1');

    let valorliquido = valor - p * valor;
    setValorLiquido(valorliquido);
    setValorporparcela(valor / Number(parcelas));
    setValornaconta(0.7 * valorliquido);
    setValordecreditos(0.3 * valorliquido);
    setValorcomtaxa(valor / (1 - p));
    setValorparcelacomtaxa(valor / (1 - p) / Number(parcelas));
  }

  function pesquisacep(valor) {
    setEndereco({ cep: valor });
    var numcep = valor.replace(/\D/g, '');

    if (numcep !== '') {
      var validacep = /^[0-9]{8}$/;

      if (validacep.test(numcep)) {
        document.getElementById('rua').value = '...';
        document.getElementById('bairro').value = '...';
        document.getElementById('cidade').value = '...';
        document.getElementById('uf').value = '...';

        cep.get(`/cep.json?code=${numcep}`).then((response) => {
          if (response.data.status === 200) {
            setEndereco({
              cep: response.data.code,
              rua: response.data.address,
              bairro: response.data.district,
              cidade: response.data.city,
              uf: response.data.state,
            });
            setErroCep();
            setErroRua();
            setErrobairro();
            setErroCidade();
            setErroUf();
          } else if (response.data.status === 404) {
            setErroCep('CEP não encontrado');
            setEndereco({
              cep: valor,
              rua: '',
              bairro: '',
              cidade: '',
              uf: '',
            });
          }
        });
      }
    }
  }

  const lojas = [
    'GMAD ABC',
    'GMAD Americana',
    'GMAD BM Madeiras',
    'GMAD Bridi Madeiras',
    'GMAD Buogini',
    'GMAD Campinas',
    'GMAD Campo Grande',
    'GMAD Campo Largo',
    'GMAD Canoas',
    'GMAD Cariri',
    'GMAD Casa da Madeira Anápolis',
    'GMAD Casa da Madeira - Brasília',
    'GMAD Casa do Marceneiro - GO T63',
    'GMAD Casa da Madeira Taguatinga',
    'GMAD Casa do Marceneiro - GO Coimbra',
    'GMAD Casa do Marceneiro - Palmas',
    'GMAD Casa do MDF',
    'GMAD Centro do Alumínio',
    'GMAD Centro Oeste',
    'GMAD Chapecomp',
    'GMAD Compin',
    'GMAD Complond',
    'GMAD Compmag',
    'GMAD Feira de Santana',
    'GMAD Flaviense Campo Grande',
    'GMAD Flaviense Curicica',
    'GMAD Flaviense Duque - (Filial 2)',
    'GMAD Flaviense Duque',
    'GMAD Flaviense Nova Iguaçu',
    'GMAD Flaviense São Gonçalo',
    'GMAD Flaviense Zona Norte',
    'GMAD Flaviense Maricá',
    'GMAD Fortaleza',
    'GMAD Guarapuava',
    'GMAD Jamad Cabedelo',
    'GMAD Jamad Natal',
    'GMAD JK Madeiras',
    'GMAD JKV Madeiras',
    'GMAD JR Madeiras',
    'GMAD JR Madeiras - Volta Redonda - RJ',
    'GMAD Lojão do Marceneiro - Porto Velho - RO',
    'GMAD Lojão do Marceneiro - Matriz',
    'GMAD Lojão do Marceneiro Ji Paraná',
    'GMAD Lojão do Marceneiro Rio Branco',
    'GMAD Madcaju',
    'GMAD Madcentro',
    'GMAD Madcentro Itu',
    'GMAD Madcentro JundiaÍ',
    'GMAD Madcompen',
    'GMAD Madecari',
    'GMAD Madecenter Caruaru',
    'GMAD Madecenter Centro 2',
    'GMAD Madecenter Imbiribeira',
    'GMAD Madecenter Matriz',
    'GMAD Madeireira Palowa BR040',
    'GMAD Madeireira Palowa Ipatinga',
    'GMAD Madeireira Palowa Tamoios',
    'GMAD Madeireira Palowa Tupis',
    'GMAD Madplex',
    'GMAD Madville',
    'GMAD MF Madeiras',
    'Gmad Montes Claros',
    'GMAD Riga Madeiras - Vila Ré',
    'GMAD Pinheirinho',
    'GMAD Placa Foz',
    'GMAD Placa Sul',
    'GMAD Placas Uberaba',
    'GMAD Placavel',
    'GMAD Ponto do Marceneiro',
    'GMAD Pouso Alegre',
    'GMAD Ribeirão',
    'GMAD Riga Madeiras - Itaquaquecetuba',
    'GMAD Rio Preto',
    'GMAD RV Madeiras',
    'GMAD Salvador',
    'GMAD Segmar',
    'GMAD Taboão Madeiras',
    'GMAD Vitória da Conquista',
    'GMAD Santo Antônio de Jesus',
    'GMAD Flaviense Juiz de Fora',
    'GMAD Flaviense Itaguaí',
  ];
  const [loja, setLoja] = useState('');

  const formik = useFormik({
    initialValues: {
      loja: loja,
      vendedor: '',
      cnpj: empresa.cnpj,
      incricaoestadual: '',
      razaosocial: empresa.razaosocial,
      nomefantasia: empresa.nomefantasia,
      nome: '',
      cpf: numCPF,
      nascimento: '',
      nomedamae: '',
      celular: '',
      telefonefixo: '',
      email1: '',
      email2: '',
      valorpatrimonio: '',
      rendamensal: '',
      cep: endereco.cep,
      endereco: endereco.rua,
      numero: '',
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      uf: endereco.uf,
      banco: '',
      agencia: '',
      conta: '',
      tipodaconta: '',
    },

    validationSchema: Yup.object({
      // loja: Yup.string().required('Campo obrigatório'),
      nome: Yup.string().required('Campo obrigatório'),
      nascimento: Yup.string().required('Campo obrigatório'),
      nomedamae: Yup.string().required('Campo obrigatório'),
      celular: Yup.string().required('Campo obrigatório'),
      email1: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo obrigatório'),
      valorpatrimonio: Yup.string().required('Campo obrigatório'),
      rendamensal: Yup.string().required('Campo obrigatório'),
      numero: Yup.string().required('Campo obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
            <h4>Dados para cadastro</h4>
            <p>Loja GMAD em que realiza as compras: ${loja}</p>
            <p>Vendedor: ${values.vendedor}</p>
            <h4>Dados da marcenaria</h4>
            <p>CNPJ: ${empresa.cnpj}</p>
            <p>Incrição estadual: ${values.incricaoestadual}</p>
            <p>Razão social: ${empresa.razaosocial}</p>
            <p>Nome fantasia da marcenaria: ${empresa.nomefantasia}</p>
            <h4>Dados do proprietário</h4>
            <p>Nome completo: ${values.nome}</p>
            <p>CPF: ${numCPF}</p>
            <p>Data de nascimento: ${values.nascimento}</p>
            <p>Nome da mãe: ${values.nomedamae}</p>
            <p>Celular: ${values.celular}</p>
            <p>Telefone fixo: ${values.telefonefixo}</p>
            <p>E-mail 1: ${values.email1}</p>
            <p>E-mail 2: ${values.email2}</p>
            <p>Valor do Patrimônio: R$${values.valorpatrimonio}</p>
            <p>Renda mensal: R$${values.rendamensal}</p>
            <p>Pessoa exposta politicamente: ${pep}</p>
            <h4>Endereço da marcenaria</h4>
            <p>CEP: ${endereco.cep}</p>
            <p>Endereço: ${endereco.rua}</p>
            <p>Número: ${values.numero}</p>
            <p>Bairro: ${endereco.bairro}</p>
            <p>Cidade: ${endereco.cidade}</p>
            <p>Estado: ${endereco.uf}</p>
            <h4>Dados bancários</h4>
            <p>Banco: ${values.banco}</p>
            <p>Agência: ${values.agencia}</p>
            <p>Conta: ${values.conta}</p>
            <p>Tipo da conta: ${tipodaconta}</p>
          `;

      const mail = {
        to: 'contato.gmais@gmad.com.br', //contato.gmais@gmad.com.br
        from: values.email1,
        as: values.nome,
        // bcc: JSON.stringify(['orlei@agenciaade.com.br']),
        subject: 'Novo interessado na Maquininha G+',
        message: body,
      };

      const baseUrl = `https://script.google.com/macros/s/AKfycbzdzPQ71bgs-KJDUHqdrhlIoBJ84K28sucnoQiuWFIVqe9ov2g3/exec?loja=${values.loja}&vendedor=${values.vendedor}&cnpj=${empresa.cnpj}&inscricaoestadual=${values.incricaoestadual}&razaosocial=${empresa.razaosocial}&nomefantasia=${empresa.nomefantasia}&nome=${values.nome}&cpf=${numCPF}&nascimento=${values.nascimento}&nomedamae=${values.nomedamae}&celular=${values.celular}&telefonefixo=${values.telefonefixo}&email1=${values.email1}&email2=${values.email2}&cep=${endereco.cep}&rua=${endereco.rua}&numero=${values.numero}&bairro=${endereco.bairro}&cidade=${endereco.cidade}&uf=${endereco.uf}&banco=${values.banco}&agencia=${values.agencia}&conta=${values.conta}&tipodaconta=${tipodaconta}`;

      try {
        // sucesso ao enviar
        setDisabledButton(true);

        if (
          endereco.cep !== '' &&
          endereco.rua !== '' &&
          endereco.bairro !== '' &&
          endereco.cidade !== '' &&
          endereco.uf !== '' &&
          validoCPF !== false &&
          errocnpj !== 'Informe um CNPJ válido' &&
          loja !== ''
        ) {
          await axios
            .post('https://api.agenciaade.com.br/email', mail, {
              headers: {
                'content-type': 'application/json',
              },
            })
            .then((response) => {
              console.log(response);
            });

          console.log(values.email1);

          // await axios.post(baseUrl).then(() => {
          //   console.log(values);
          // });

          notification.success({
            message: 'Contato enviado com sucesso! 🚀',
            placement: 'topRight',
          });

          setEndereco({
            cep: '',
            rua: '',
            bairro: '',
            cidade: '',
            uf: '',
          });
          setEmpresa({
            cnpj: '',
            razaosocial: '',
            nomefantasia: '',
          });
          setNumCPF('');
          setValidoCPF();
          setTipodaConta('Conta Corrente');
          setPep('Não');
          resetForm();
          setDisabledButton(false);
          setLoja('');
        } else {
          notification.error({
            message: 'Não foi possivel enviar o contato! 😔',
            description: 'Verfique os campos e tente novamente',
            placement: 'topRight',
          });
        }
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'topRight',
        });
      }
      setDisabledButton(false);
    },
  });

  function pesquisaCNPJ(valor) {
    setEmpresa({ cnpj: valor });
    const valorCNPJ = valor.replace(/\D/g, '');

    if (valorCNPJ.length === 14) {
      document.getElementById('razaosocial').value = '...';
      document.getElementById('nomefantasia').value = '...';

      cnpj
        .get(`${valorCNPJ}`, {
          headers: {
            authorization:
              'c24f1e85-4179-4786-8af5-a2c2d8351d3a-9e4cb22d-0af4-421b-ba9b-30e0bce00fb0',
          },
        })
        .then((response) => {
          console.log(response.data);
          setEmpresa({
            cnpj: valor,
            razaosocial: response.data.name,
            nomefantasia: response.data.alias,
          });
          setErroCnpj(null);

          if (response.data.alias === null) {
            setEmpresa({
              cnpj: valor,
              razaosocial: response.data.name,
              nomefantasia: '',
            });
          }
        })
        .catch((err) => {
          setErroCnpj('Informe um CNPJ válido');
          setEmpresa({
            cnpj: valor,
            razaosocial: '',
            nomefantasia: '',
          });
        });
    }
  }

  function TestaCPF(valor) {
    var Soma;
    var Resto;
    Soma = 0;
    setNumCPF(valor);
    const valorCPF = valor.replace(/\D/g, '');
    if (valorCPF === '00000000000') return setValidoCPF(false);

    for (let i = 1; i <= 9; i += 1)
      Soma = Soma + parseInt(valorCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(valorCPF.substring(9, 10)))
      return setValidoCPF(false);

    Soma = 0;
    for (let i = 1; i <= 10; i += 1)
      Soma = Soma + parseInt(valorCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(valorCPF.substring(10, 11)))
      return setValidoCPF(false);
    return setValidoCPF(true);
  }

  function handleSelectedOption(value) {
    setParcelas(value);
  }

  function handleBandeira(value) {
    setBandeira(value);
  }

  function handleSubmit() {
    if (endereco.cep === '') {
      setErroCep('Campo Obrigatório');
    }

    if (endereco.rua === '') {
      setErroRua('Campo Obrigatório');
    }

    if (endereco.bairro === '') {
      setErrobairro('Campo Obrigatório');
    }

    if (endereco.cidade === '') {
      setErroCidade('Campo Obrigatório');
    }

    if (endereco.uf === '') {
      setErroUf('Campo Obrigatório');
    }
  }

  return (
    <>
      <Helmet>
        <title>GMAD | Maquininha G+</title>
        <meta
          name="description"
          content="G+ são iniciativas GMAD para apoiar ainda mais sua marcenaria. A maquininha é uma delas. Uma opção prática e competitiva de parcelamento a seu cliente e o recebimento do seu serviço à vista."
        />
        {/* <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        /> */}
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header />
      {/* <Rodal
        customStyles={{
          background: 'none',
          padding: '2rem',
          height: '30rem',
          width: '40rem',
        }}
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <a style={{ background: 'none' }} href="/#simuladordetaxas">
          <img src={require('../../assets/img/popup.png')} alt="" />
        </a>
      </Rodal> */}
      <Container>
        <Banner>
          <div className="texto">
            <img
              src={require('../../assets/img/vocepodemais.png')}
              alt="Você pode mais com G+"
            />
            <p>
              <strong>G+</strong> são iniciativas <strong>GMAD</strong> para
              apoiar ainda mais sua marcenaria. A maquininha é uma delas. Uma
              opção prática e competitiva de parcelamento a seu cliente e o
              recebimento do seu serviço à vista.{' '}
            </p>
          </div>
          <div className="imagem">
            <img
              src={require('../../assets/img/maquininhabanner.png')}
              alt="Maquininha G+"
            />
          </div>
        </Banner>
        <Porque id="porqueaderir">
          <div className="porque">
            <div className="bg-img">
              <img
                src={require('../../assets/img/aderir.jpg')}
                alt="Imagem de fundo"
              />
            </div>
            <div className="text">
              <div className="inner-text">
                <h4>
                  Por que <strong>aderir?</strong>
                </h4>
                <h4>
                  Mais uma ferramenta essencial para sua{' '}
                  <strong>marcenaria</strong>
                </h4>
                <p>Agora você pode parcelar suas vendas em até 18x</p>
                <a href="#tenhointeresse">SOLICITE A SUA MAQUININHA</a>
              </div>
            </div>
          </div>
          <div className="podemais">
            <h4>
              Você pode mais com <strong>G+</strong>
            </h4>
            <div className="cards">
              <div>
                <div className="icon">
                  <img
                    src={require('../../assets/img/dinheiro.svg')}
                    alt="+ Dinheiro na mão"
                  />
                </div>
                <p className="title">+ Dinheiro na mão</p>
                <p>Recebimento à vista do seu serviço</p>
              </div>
              <div>
                <div className="icon">
                  <img
                    src={require('../../assets/img/negocios.svg')}
                    alt="+ Negócios"
                  />
                </div>
                <p className="title">+ Negócios</p>
                <p>Opção prática de parcelamento para seu cliente</p>
                <p>
                  Ampliar o parcelamento, pode facilitar o fechamento de mais
                  ambientes com o mesmo cliente
                </p>
              </div>
              <div>
                <div className="icon">
                  <img
                    src={require('../../assets/img/competitividade.png')}
                    alt="+ Competitividade"
                  />
                </div>
                <p className="title">+ Competitividade</p>
                <p>Taxas bem negociadas</p>
              </div>
              <div>
                <div className="icon">
                  <img
                    src={require('../../assets/img/praticidade.png')}
                    alt="+ Praticidade"
                  />
                </div>
                <p className="title">+ Praticidade</p>
                <p>Conte com seu vendedor GMAD para qualquer apoio</p>
              </div>
              <div>
                <div className="icon">
                  <img
                    src={require('../../assets/img/transparencia.png')}
                    alt="+ Transparência"
                  />
                </div>
                <p className="title">+ Transparência</p>
                <p>Tudo à mão e as claras</p>
              </div>
            </div>
          </div>
          <img
            id="detail"
            src={require('../../assets/img/mais-detail.png')}
            alt="Mais"
          />
        </Porque>

        <Funciona>
          <h4 id="comofunciona">
            Como <strong>funciona?</strong>
          </h4>
          <div className="cards">
            <div>
              <p>
                <strong>GMAD</strong> negociou taxas bem competitivas e quer
                estender aos seus clientes parceiros
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro
                <strong> adere</strong>
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro preenche uma <strong>ficha cadastral</strong> com
                dados
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro <strong>recebe a maquininha e imediatamente</strong>{' '}
                pode começar a parcelar
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
            <div>
              <p>
                Marceneiro assina um <strong>termo de responsabilidade</strong>{' '}
                e alinha as condições de adesão da maquininha com a loja GMAD
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                <strong>GMAD</strong> envia dados ao banco e ativa a maquininha
                em nome do marceneiro
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Ao parcelar para seu cliente, no dia útil seguinte:
                <br />
                <strong>Recebe 70% do valor já descontadas as taxas</strong>,
                direto na sua conta bancária
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
            <div>
              <p>
                E os <strong>30% restantes ficam como crédito</strong> seu na
                GMAD
              </p>
            </div>
          </div>

          {/* Para screen 680px */}

          <div className="cardsresizedfor2">
            <div>
              <p>
                <strong>GMAD</strong> negociou taxas bem competitivas e quer
                estender aos seus clientes parceiros
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro
                <strong> adere</strong>
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                <strong>GMAD</strong> envia dados ao banco e ativa a maquininha
                em nome do marceneiro
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro preenche uma <strong>ficha cadastral</strong> com
                dados
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro assina um <strong>termo de responsabilidade</strong>{' '}
                e alinha as condições de adesão da maquininha com a loja GMAD
              </p>
              <div className="next">
                <img src={arrow} alt="Arrow" />
              </div>
            </div>
            <div>
              <p>
                Marceneiro <strong>recebe a maquininha e imediatamente</strong>{' '}
                pode começar a parcelar
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>

            <div>
              <p>
                E os <strong>30% restantes ficam como crédito</strong> seu na
                GMAD
              </p>
            </div>
            <div>
              <p>
                Ao parcelar para seu cliente, no dia útil seguinte:
                <br />
                <strong>Recebe 70% do valor já descontadas as taxas</strong>,
                direto na sua conta bancária
              </p>
              <div className="bottom">
                <img
                  src={require('../../assets/img/bottom-arrow.svg')}
                  alt="Arrow"
                />
              </div>
            </div>
          </div>
          <img
            id="detail"
            src={require('../../assets/img/mais-laranja.png')}
            alt="Mais"
          />
        </Funciona>

        <LinkApps>
          <div className="texto" id="vantagens">
            <h2>
              O <strong>próprio App simula as taxas</strong> na hora da venda
            </h2>
            <h4>Muito mais opções na sua mão!</h4>
            <p>
              A melhor ferramenta para tomada de decisão na hora que você está
              com seu cliente
            </p>

            <div className="app-links">
              <a
                href="https://apps.apple.com/br/app/gmais-simulador-de-taxas/id1537651203"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/app-store.svg')}
                  alt="Link App Store"
                />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=br.com.websix.gmais&hl=pt_BR&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../assets/img/play-store.svg')}
                  alt="Link Play Store"
                />
              </a>
            </div>
          </div>
          <div className="imagem-cel">
            <img
              src={require('../../assets/img/celular-mais-novo.png')}
              alt="Maquininha G+"
            />
          </div>
        </LinkApps>

        <Credenciamento id="credenciamento">
          <h4>
            Como funciona o processo de credenciamento de marceneiros, para
            receber a <strong>Maquininha G+</strong>?
          </h4>
          <div className="content">
            <div className="cards">
              <div className="card">
                <h4>1.</h4>
                <div>
                  <p>Preencha a ficha cadastral clicando no botão</p>
                  <a href="#tenhointeresse">FORMULÁRIO FICHA CADASTRAL</a>
                </div>
              </div>
              <div className="card">
                <h4>2.</h4>
                <p>
                  Em até 7 dias úteis faremos seu cadastro e em caso de dúvidas,
                  ligaremos para você
                </p>
              </div>
              <div className="card">
                <h4>3.</h4>
                <p>
                  Você recebe um e-mail do Pagseguro, onde é necessário clicar e
                  criar uma senha
                </p>
              </div>
              <div className="card">
                <h4>4.</h4>
                <p>
                  A maquininha é enviada para a loja GMAD e o vendedor te liga
                  para que você vá até lá para retirar
                </p>
              </div>
              <div className="card">
                <h4>5.</h4>
                <p>
                  Você assina os termos de adesão e ativa a maquininha junto ao
                  seu vendedor GMAD.
                </p>
              </div>
            </div>
            <div className="imagem">
              <img
                src={require('../../assets/img/maquininha.png')}
                alt="Maquininha G+"
              />
              <div id="bg-green" />
            </div>
          </div>
        </Credenciamento>

        <Videos>
          <button type="button" onClick={() => videosRef.current.prev()}>
            <FaChevronLeft size={32} />
          </button>
          <div className="carousel-container">
            <Carousel ref={videosRef} {...carouselVideos} effect="fade">
              <div className="container-iframe">
                {/* <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0pN-5WG76Pk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/yepoJ00rhfQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Carousel>
          </div>
          <button type="button" onClick={() => videosRef.current.next()}>
            <FaChevronRight size={32} />
          </button>
        </Videos>

        {/* <Dicas id="dicas">
          <h4>Dicas</h4>
          <div className="cards">
            {!!dicas &&
              dicas.map((dica) => {
                const year = dica.date.substring(0, 4);
                const month = dica.date.substring(5, 7);
                const day = dica.date.substring(8, 10);

                return (
                  <a className="card" href={`/blog/${dica.slug}`} key={dica.id}>
                    <div className="imagem">
                      <img
                        src={dica._embedded['wp:featuredmedia'][0].source_url}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="date">
                        {day}/{month}/{year}
                      </p>
                      <p className="title">{dica.title.rendered}</p>

                      {ReactHtmlParser(dica.excerpt.rendered)}

                      <button type="button">CONTINUAR LENDO</button>
                    </div>
                  </a>
                );
              })}
          </div>
          {loading && <div className="circle"></div>}
          <button
            type="button"
            onClick={() => {
              setNumerodeposts(numerodeposts + 6);
              setLoading(true);
            }}
          >
            CARREGAR MAIS
          </button>
        </Dicas> */}
        <Simulador style={{ display: 'none' }} id="simuladordetaxas">
          <h4>
            Simulador de <strong>taxas</strong>
          </h4>
          <div className="firstsbox">
            <div className="main-box">
              <div className="select">
                <div className="inputfield">
                  <label>Valor da venda:</label>
                  <CurrencyInput
                    name="valor"
                    value={valordavenda}
                    onChange={(maskedvalue) => {
                      setValordavenda(maskedvalue);
                      setErroValor();
                    }}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                  />
                </div>
                {erroValor && <span>{erroValor}</span>}
              </div>

              <div className="select">
                <div className="inputfield">
                  <label>Número de parcelas:</label>
                  <Select value={parcelas} onChange={handleSelectedOption}>
                    <Option value="0">Débito</Option>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="7">7</Option>
                    <Option value="8">8</Option>
                    <Option value="9">9</Option>
                    <Option value="10">10</Option>
                    <Option value="11">11</Option>
                    <Option value="12">12</Option>
                    <Option value="13">13</Option>
                    <Option value="14">14</Option>
                    <Option value="15">15</Option>
                    <Option value="16">16</Option>
                    <Option value="17">17</Option>
                    <Option value="18">18</Option>
                  </Select>
                </div>
              </div>

              <div className="select">
                <div className="inputfield">
                  <label>Bandeira:</label>
                  <Select value={bandeira} onChange={handleBandeira}>
                    <Option value="0">Visa master</Option>
                    <Option value="1">Elo</Option>
                    <Option value="2">Demais</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="firstsbox-s3">
              <button type="button" onClick={CalcularTaxas}>
                CALCULAR
              </button>
            </div>
          </div>

          <fieldset id="resultados">
            <div className="firsthalf">
              <div>
                <label>Valor por parcela para o consumidor:</label>
                <div className="resultado">
                  <CurrencyInput
                    value={valorporparcela}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
                <label>Valor líquido total da venda:</label>

                <div className="resultado">
                  <CurrencyInput
                    value={valorLiquido}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
              </div>
              <div>
                <label>
                  Valor que entra na sua conta no dia seguinte (1o dia útil):
                </label>
                <div className="resultado">
                  <CurrencyInput
                    value={valornaconta}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
                <label>Valor de créditos na GMAD:</label>
                <div className="resultado">
                  <CurrencyInput
                    value={valordecreditos}
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="secondhalf">
              <p className="title">
                Caso queira incluir a taxa do cartão no valor da venda:
              </p>
              <div>
                <div>
                  <label>Valor total a cobrar:</label>
                  <div className="resultado">
                    <CurrencyInput
                      value={valorcomtaxa}
                      prefix="R$"
                      decimalSeparator=","
                      thousandSeparator="."
                      disabled
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Valor por parcela para o Consumidor, com a taxa do cartão
                  </label>
                  <div className="resultado">
                    <CurrencyInput
                      value={valorparcelacomtaxa}
                      prefix="R$"
                      decimalSeparator=","
                      thousandSeparator="."
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* <p>
                *taxas por bandeira: 0,90% débito; 1,70% crédito (recebimento
                D+1); 2,10% parcelado de 2 a 18 vezes
              </p>
              <p>**taxa de antecipação do parcelado: 1,10% ao mês</p> */}
            </div>
          </fieldset>
        </Simulador>
        <Perguntas id="perguntasfrequentes">
          <h4>
            FAQ <strong>(Perguntas Frequentes)</strong>
          </h4>
          <Collapse>
            <Panel
              header="O QUE PRECISO FAZER PARA RECEBER A MAQUININHA?"
              key="1"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Providenciar o preenchimento de uma ficha cadastral com dados
                básicos como endereço, e-mail, celular, dados bancários. Ao
                receber a maquininha, precisará também assinar um termo de
                responsabilidade com a loja.
              </p>
              <p>
                *o seu cadastro passará por uma aprovação prévia da loja GMAD.
              </p>
            </Panel>
            <Panel
              header="E SE EU TIVER ALGUMA RESTRIÇÃO DE CRÉDITO?"
              key="2"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Não tem problema, mas poderá haver análise da PagSeguro
                dependendo do valor de antecipação.
              </p>
            </Panel>
            <Panel
              header="QUAL O PRAZO PARA O DINHEIRO CAIR NA MINHA CONTA BANCÁRIA?"
              key="3"
              showArrow={false}
              extra={genExtra()}
            >
              <p>Cairá no dia útil seguinte à transação</p>
            </Panel>
            <Panel
              header="MEU DINHEIRO FICA PRESO COM VOCÊS?"
              key="4"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                No dia (útil) seguinte à transação, você receberá 70% do valor
                descontado as taxas na sua conta Pagbank, onde você poderá
                transferir à conta desejada, e os 30% restantes vão direto para
                a sua conta na GMAD, onde imediatamente você pode utilizar na
                compra de materiais.
              </p>
            </Panel>
            <Panel
              header="EU PRECISO TER CONTA EM BANCO?"
              key="5"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Não, após o recebimento da sua maquininha G+, você ativará a
                conta digital da Pagseguro de forma gratuita, para a
                transferência dos valores a conta que você desejar. Você recebe
                um cartão pré-pago para poder transferir o saldo e efetuar saque
                em caixa 24hs. Pode pagar contas através dessa conta digital
                Pagseguro, realizar TEDS para outros bancos. Mas podem existir
                custos por transação. Consulte seu vendedor GMAD.
              </p>
            </Panel>
            {/* <Panel
              header="A CONTA BANCÁRIA PARA CRÉDITO DO VALOR PODE SER POUPANÇA?"
              key="6"
              showArrow={false}
              extra={genExtra()}
            >
              <p>Claro, conta corrente ou poupança.</p>
            </Panel> */}
            <Panel
              header="QUAL O MÁXIMO DE PARCELAMENTO QUE PODE SER FEITO?"
              key="7"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                O parcelamento permitido na maquininha é em até 18x e em todas
                as formas de parcelamento, o marceneiro e loja recebem o valor
                no dia seguinte à transação. Contudo entre 13 e 18 parcelas,
                somente com os cartões do Banco do Brasil e Caixa Econômica
                mediante aprovação do portador do cartão junto ao banco emissor.
              </p>
              <p>
                Alguns cartões do banco Bradesco também são aceitos o
                parcelamento máximo, mas também necessitam da solicitação de
                aprovação do portador do cartão junto ao banco emissor.
              </p>
            </Panel>
            <Panel
              header="CASO O PARCELAMENTO SEJA REALIZADO EM 10 VEZES, O CRÉDITO NA CONTA É FEITO À VISTA?"
              key="8"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Sim, para todo e qualquer número de parcelas (máximo até 18),
                sempre cai à vista ou seja, no dia útil seguinte à transação.
              </p>
            </Panel>
            <Panel
              header="QUANTAS MÁQUINAS PODEM SER ADQUIRIDAS POR CADA EMPRESA OU PESSOA FÍSICA?"
              key="9"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Nesse primeiro momento, só disponibilizaremos 1 máquina por
                marcenaria. Futuramente será possível solicitar mais de uma.
              </p>
            </Panel>
            <Panel
              header="QUAL A TAXA MENSAL PARA USO DA MÁQUINA?"
              key="10"
              showArrow={false}
              extra={genExtra()}
            >
              <p>Você deve consultar as condições com seu vendedor GMAD.</p>
            </Panel>
            <Panel
              header="SE O MARCENEIRO NÃO UTILIZAR A MÁQUINA POR ALGUNS MESES, PRESCREVE?"
              key="11"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Não prescreve, contudo as negociações de taxas estão
                condicionadas a um volume mínimo de operações e para confirmar,
                consulte seu vendedor GMAD.
              </p>
            </Panel>
            <Panel
              header="COMO FICA A QUESTÃO DE IMPOSTO DE RENDA DO MARCENEIRO?"
              key="12"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Como sempre foi feito, em se tratando de um assunto interno da
                marcenaria. Sugerimos que consulte o seu contador.
              </p>
            </Panel>
            <Panel
              header="CASO O MARCENEIRO PERCA OU AVARIE A MÁQUINA, COMO PROCEDEMOS?"
              key="13"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Você deve entrar em contato com a loja para solicitar
                manutenção, troca do aparelho ou reposição.
              </p>
            </Panel>
            <Panel
              header="E SE EU JÁ TENHO UMA MÁQUINA PAGSEGURO MAS QUERO MIGRAR PARA A DA GMAD, DEVIDO MELHORES CONDIÇÕES?"
              key="14"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Até podemos tentar usar a máquina que você já tem, mas seria um
                processo moroso, então sugerimos que peça uma máquina nova à
                GMAD e desabilite a anterior.
              </p>
            </Panel>
            <Panel
              header="MARCENEIRO PODE TER DUAS MÁQUINAS PAGSEGURO NO MESMO CPF OU CNPJ?"
              key="15"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Sim, mas será analisado por sua loja GMAD, mediante sua
                necessidade e uso com o aparelho.
              </p>
            </Panel>
            <Panel
              header="CASO EU QUEIRA DEVOLVER O APARELHO, COMO DEVO PROCEDER?"
              key="16"
              showArrow={false}
              extra={genExtra()}
            >
              <p>
                Você deverá devolver o aparelho juntamente com os acessórios que
                você recebeu na sua loja GMAD. Lembre-se! Você assinou em
                contrato a responsabilidade de entregá-lo completo e em bom
                estado.
              </p>
            </Panel>
          </Collapse>
          <div className="duvidas">
            <div className="d1">
              <h4>
                Mais <strong>Dúvidas?</strong>
              </h4>
            </div>

            <div className="d2">
              <div>
                <p>
                  Conte sempre com seu <strong>vendedor GMAD</strong> por whats,
                  na loja ou até na sua marcenaria.
                </p>
                <p>
                  Além de contar com o seu vendedor GMAD, a Central de
                  Atendimento G+ estará à disposição para te auxiliar no que
                  precisar! De segunda à sexta das 8h às 12h e das 13h30 às 18h.
                </p>
                <br />
              </div>
              <div>
                <p>
                  Por e-mail no{' '}
                  <a
                    href="mailto:contato.gmais@gmad.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail />
                    contato.gmais@gmad.com.br
                  </a>{' '}
                  <a
                    href="mailto:atendimento1@gmad.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail />
                    atendimento1@gmad.com.br
                  </a>
                </p>
                <p>
                  ou pelo whatsapp no{' '}
                  <a
                    href="https://wa.me/5542991135586"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp />
                    (42) 99113-5586
                  </a>
                  <a
                    href="https://wa.me/5542991286122"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp />
                    (42) 9128-6122
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Perguntas>
        <Interesse id="tenhointeresse">
          <h4>
            Tenho Interesse na <strong>Maquininha</strong>
          </h4>
          <form onSubmit={formik.handleSubmit}>
            <fieldset>
              <p className="title">
                Dados para <strong>cadastro</strong>
              </p>
              <div className="cadastro">
                <div>
                  <label>
                    Em qual loja GMAD você costuma fazer suas compras?*
                  </label>
                  <select
                    value={loja}
                    onChange={(e) => setLoja(e.target.value)}
                    onBlur={formik.handleBlur}
                    className="select-loja"
                  >
                    <option value="" disabled={true} hidden={true}>
                      Selecione uma loja GMAD
                    </option>
                    {lojas.map((lol) => {
                      return (
                        <option value={lol} key={lol}>
                          {lol}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input
                    type="text"
                    name="loja"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.loja}
                    className={
                      formik.touched.loja && formik.errors.loja
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.loja && formik.errors.loja ? (
                    <span>{formik.errors.loja}</span>
                  ) : null} */}
                </div>
                <div>
                  <label>Com qual vendedor?</label>
                  <input
                    type="text"
                    name="vendedor"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vendedor}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <p className="title">
                Dados da <strong>marcenaria</strong>
              </p>
              <div className="marcenaria">
                <div>
                  <label>CNPJ</label>
                  <Input
                    type="tel"
                    mask="99.999.999/9999-99"
                    maskChar={null}
                    name="cnpj"
                    value={empresa.cnpj}
                    onChange={(e) => {
                      pesquisaCNPJ(e.target.value);
                    }}
                    className={errocnpj ? 'redborder' : ''}
                  />
                  {errocnpj ? <span>{errocnpj}</span> : null}
                </div>
                <div>
                  <label>Inscrição estadual</label>
                  <input
                    type="text"
                    name="incricaoestadual"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.incricaoestadual}
                  />
                </div>
                <div>
                  <label>Razão social</label>
                  <input
                    id="razaosocial"
                    type="text"
                    name="razaosocial"
                    value={empresa.razaosocial}
                    onChange={(e) =>
                      setEmpresa({
                        cnpj: empresa.cnpj,
                        razaosocial: e.target.value,
                        nomefantasia: empresa.nomefantasia,
                      })
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div>
                  <label>Nome fantasia da marcenaria</label>
                  <input
                    id="nomefantasia"
                    type="text"
                    name="nomefantasia"
                    onChange={(e) =>
                      setEmpresa({
                        cnpj: empresa.cnpj,
                        razaosocial: empresa.razaosocial,
                        nomefantasia: e.target.value,
                      })
                    }
                    onBlur={formik.handleBlur}
                    value={empresa.nomefantasia}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <p className="title">
                Dados do <strong>proprietário</strong>
              </p>
              <div className="proprietario1">
                <div>
                  <label>Nome completo (sem abreviações)*</label>
                  <input
                    type="text"
                    name="nome"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                    className={
                      formik.touched.nome && formik.errors.nome
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div>
                  <label>CPF*</label>
                  <Input
                    type="tel"
                    mask="999.999.999-99"
                    maskChar={null}
                    name="cpf"
                    onChange={(e) => TestaCPF(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={numCPF}
                    className={validoCPF === false ? 'redborder' : ''}
                  />
                  {validoCPF === true && (
                    <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      CPF válido <AiOutlineCheckCircle />
                    </span>
                  )}
                  {validoCPF === false && <span>Informe um CPF válido</span>}
                </div>
                <div>
                  <label>Data de nascimento*</label>
                  <Input
                    type="tel"
                    mask="99/99/9999"
                    maskChar={null}
                    name="nascimento"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nascimento}
                    className={
                      formik.touched.nascimento && formik.errors.nascimento
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nascimento && formik.errors.nascimento ? (
                    <span>{formik.errors.nascimento}</span>
                  ) : null}
                </div>
                <div>
                  <label>Nome da mãe*</label>
                  <input
                    type="text"
                    name="nomedamae"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nomedamae}
                    className={
                      formik.touched.nomedamae && formik.errors.nomedamae
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.nomedamae && formik.errors.nomedamae ? (
                    <span>{formik.errors.nomedamae}</span>
                  ) : null}
                </div>
              </div>
              <div className="proprietario2">
                <div>
                  <label>Celular*</label>
                  <Input
                    type="tel"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    name="celular"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.celular}
                    className={
                      formik.touched.celular && formik.errors.celular
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.celular && formik.errors.celular ? (
                    <span>{formik.errors.celular}</span>
                  ) : null}
                </div>
                <div>
                  <label>Telefone fixo</label>
                  <Input
                    type="tel"
                    mask="(99) 9999-9999"
                    maskChar={null}
                    name="telefonefixo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telefonefixo}
                    className={
                      formik.touched.telefonefixo && formik.errors.telefonefixo
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.telefonefixo && formik.errors.telefonefixo ? (
                    <span>{formik.errors.telefonefixo}</span>
                  ) : null}
                </div>
                <div>
                  <label>E-mail 1*</label>
                  <input
                    type="email"
                    name="email1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email1}
                    className={
                      formik.touched.email1 && formik.errors.email1
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.email1 && formik.errors.email1 ? (
                    <span>{formik.errors.email1}</span>
                  ) : null}
                </div>
                <div>
                  <label>E-mail 2</label>
                  <input
                    type="email"
                    name="email2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email2}
                  />
                </div>
                <div>
                  <label>Valor do patrimônio</label>
                  <input
                    type="text"
                    name="valorpatrimonio"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.valorpatrimonio}
                    className={
                      formik.touched.valorpatrimonio &&
                      formik.errors.valorpatrimonio
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.valorpatrimonio &&
                  formik.errors.valorpatrimonio ? (
                    <span>{formik.errors.valorpatrimonio}</span>
                  ) : null}
                </div>
                <div>
                  <label>Renda mensal</label>
                  <input
                    type="text"
                    name="rendamensal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rendamensal}
                    className={
                      formik.touched.rendamensal && formik.errors.rendamensal
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.rendamensal && formik.errors.rendamensal ? (
                    <span>{formik.errors.rendamensal}</span>
                  ) : null}
                </div>
                <div className="radio">
                  <div className="radio-title">
                    Pessoa exposta politicamente:
                  </div>
                  <div className="whitecircle" onClick={() => setPep('Sim')}>
                    <div className={pep === 'Sim' ? 'orangecircle' : ''} />
                  </div>
                  <div className="radio-title">Sim</div>
                  <div className="whitecircle" onClick={() => setPep('Não')}>
                    <div className={pep === 'Não' ? 'orangecircle' : ''} />
                  </div>
                  <div className="radio-title">Não</div>
                </div>
              </div>
              <p>
                Por favor informe um e-mail que ainda não foi utilizado em outra
                conta do Pagseguro.
              </p>
            </fieldset>
            <fieldset>
              <p className="title">
                Endereço da <strong>marcenaria</strong>
              </p>
              <div className="endereco1">
                <div>
                  <label>CEP</label>
                  <Input
                    name="cep"
                    type="tel"
                    id="cep"
                    value={endereco.cep}
                    mask="99999-999"
                    maskChar={null}
                    onChange={(e) => {
                      pesquisacep(e.target.value);
                      setErroCep();
                    }}
                    onBlur={formik.handleBlur}
                    className={errocep ? 'redborder' : ''}
                  />
                  {errocep ? <span>{errocep}</span> : null}
                </div>
                <div>
                  <label>Endereço*</label>
                  <input
                    name="rua"
                    type="text"
                    id="rua"
                    value={endereco.rua}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: e.target.value,
                        bairro: endereco.bairro,
                        cidade: endereco.cidade,
                        uf: endereco.uf,
                      });
                      setErroRua();
                    }}
                    className={errorua ? 'redborder' : ''}
                  />
                  {errorua ? <span>{errorua}</span> : null}
                </div>
              </div>

              <div className="endereco2">
                <div>
                  <label>Número*</label>
                  <input
                    type="tel"
                    name="numero"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.numero}
                    className={
                      formik.touched.numero && formik.errors.numero
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.numero && formik.errors.numero ? (
                    <span id="numero-span">{formik.errors.numero}</span>
                  ) : null}
                </div>
                <div>
                  <label>Bairro*</label>
                  <input
                    name="bairro"
                    type="text"
                    id="bairro"
                    value={endereco.bairro}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: e.target.value,
                        cidade: endereco.cidade,
                        uf: endereco.uf,
                      });
                      setErrobairro();
                    }}
                    className={errobairro ? 'redborder' : ''}
                  />
                  {errobairro ? <span>{errobairro}</span> : null}
                </div>
                <div>
                  <label>Cidade*</label>
                  <input
                    name="cidade"
                    type="text"
                    id="cidade"
                    value={endereco.cidade}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: endereco.bairro,
                        cidade: e.target.value,
                        uf: endereco.uf,
                      });
                      setErroCidade();
                    }}
                    className={errocidade ? 'redborder' : ''}
                  />
                  {errocidade ? <span>{errocidade}</span> : null}
                </div>
                <div>
                  <label>UF*</label>
                  <input
                    name="uf"
                    type="text"
                    id="uf"
                    size="2"
                    value={endereco.uf}
                    onChange={(e) => {
                      setEndereco({
                        cep: endereco.cep,
                        rua: endereco.rua,
                        bairro: endereco.bairro,
                        cidade: endereco.cidade,
                        uf: e.target.value,
                      });
                      setErroUf();
                    }}
                    className={errouf ? 'redborder' : ''}
                  />
                  {errouf ? <span>{errouf}</span> : null}
                </div>
              </div>
            </fieldset>
            <fieldset className="banco">
              <div className="title">
                <p>
                  Dados <strong>bancários</strong>
                </p>
                <div>
                  <div
                    className="whitecircle"
                    onClick={() => setTipodaConta('Não possuo conta bancária')}
                  >
                    <div
                      className={
                        tipodaconta === 'Não possuo conta bancária'
                          ? 'orangecircle'
                          : ''
                      }
                    />
                  </div>
                  <label>Não possuo conta bancária</label>
                </div>
              </div>
              <div className="bancario">
                <div>
                  <label>Banco*</label>
                  <input
                    type="text"
                    name="banco"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.banco}
                    className={
                      formik.touched.banco && formik.errors.banco
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.banco && formik.errors.banco ? (
                    <span>{formik.errors.banco}</span>
                  ) : null}
                </div>
                <div>
                  <label>Agência*</label>
                  <input
                    type="text"
                    name="agencia"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.agencia}
                    className={
                      formik.touched.agencia && formik.errors.agencia
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.agencia && formik.errors.agencia ? (
                    <span>{formik.errors.agencia}</span>
                  ) : null}
                </div>
                <div>
                  <label>Conta*</label>
                  <input
                    type="text"
                    name="conta"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conta}
                    className={
                      formik.touched.conta && formik.errors.conta
                        ? 'redborder'
                        : ''
                    }
                  />
                  {formik.touched.conta && formik.errors.conta ? (
                    <span>{formik.errors.conta}</span>
                  ) : null}
                </div>
                <div>
                  <div>
                    <label>Conta Corrente</label>
                    <div
                      className="whitecircle"
                      onClick={() => setTipodaConta('Conta Corrente')}
                    >
                      <div
                        className={
                          tipodaconta === 'Conta Corrente' ? 'orangecircle' : ''
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label>Conta Poupança</label>
                    <div
                      className="whitecircle"
                      onClick={() => setTipodaConta('Conta Poupança')}
                    >
                      <div
                        className={
                          tipodaconta === 'Conta Poupança' ? 'orangecircle' : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A conta bancária informada deve estar vinculada aos dados de
                cadastro. Ou seja, se o cadastro for realizado em CNPJ a conta
                deve ser jurídica e vinculada ao CNPJ informado. Se o cadastro
                for realizado em CPF, a conta bancária deve estar em nome da
                pessoa física cadastrada.
              </p>
              <p>*Campos obrigatórios</p>
            </fieldset>
            <div className="sendbtn">
              <div className={disabledButton ? 'disabledButton' : ''} />
              <button type="submit" onClick={handleSubmit}>
                FAZER SOLICITAÇÃO
              </button>
            </div>
          </form>
        </Interesse>
        <div id="bottom-footer">
          <p id="rights">GMAD © 2020 / Todos os direitos reservados</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://agenciaade.com.br/"
          >
            <p>
              Criado com{' '}
              <img src={require('../../assets/heart.svg')} alt="Coração" /> por
              agenciaade.com.br
            </p>
          </a>
        </div>
      </Container>
      <Modal
        visible={modalOpen}
        footer={null}
        closable={false}
        maskClosable={true}
        centered={true}
      >
        <a href="#" onClick={() => setModalOpen(false)}>
          <img src={require('../../assets/img/popup.png')} alt="18x" />
        </a>
        <button onClick={() => setModalOpen(false)}>
          <img src={require('../../assets/img/close-btn.svg')} alt="Close" />
        </button>
      </Modal>
    </>
  );
}
