import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { MdAccessTime } from 'react-icons/md';
import ReactHtmlParser from 'react-html-parser';

// components
import Header from '../../components/Header/Header';

// api
import api from '../../services/api';

// style
import { Content } from './style';

export default function Blog({ match }) {
  const [post, setPost] = useState();
  const [dicas, setDicas] = useState();
  const [numerodeposts, setNumerodeposts] = useState(6);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`posts?_embed&slug=${match.params.slug}`).then((response) => {
      setPost(response.data[0]);
      setLoading(false);
    });
  });

  useEffect(() => {
    setLoader(true);
    api.get(`posts?_embed&per_page=${numerodeposts}`).then((response) => {
      setDicas(response.data);
      setLoader(false);
    });
  }, [numerodeposts]);

  const year = !!post && post.date.substring(0, 4);
  const month = !!post && post.date.substring(5, 7);
  const day = !!post && post.date.substring(8, 10);

  console.log(!!post && post);

  return (
    <>
      <Helmet>
        <title>{`${
          !!post && post.title.rendered
        } - GMAD | Maquininha G+`}</title>
        <meta name="description" content={!!post && post.excerpt.rendered} />
        {/* <meta
          name="keywords"
          content="marca, campanha, site, impresso, facebook, identidade visual, logo"
        /> */}
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header />
      <Content>
        {loading && (
          <div className="Loading">
            <div className="circle"></div>
          </div>
        )}
        <div className={`imagem-principal ${loading ? 'nodisplay' : ''}`}>
          <img
            src={!!post && post._embedded['wp:featuredmedia'][0].source_url}
            alt={!!post && post._embedded['wp:featuredmedia'][0].alt}
          />
        </div>
        <div className={`content ${loading ? 'nodisplay' : ''}`}>
          <h4>{!!post && post.title.rendered.toUpperCase()}</h4>
          <p className="data">
            <MdAccessTime />
            {day}/{month}/{year}
          </p>
          {ReactHtmlParser(!!post && post.content.rendered)}
        </div>
        <h4 className={loading ? 'nodisplay' : ''}>Mais dicas</h4>
        <div className={`cards ${loading ? 'nodisplay' : ''}`}>
          {!!dicas &&
            dicas.map((dica) => {
              const year = dica.date.substring(0, 4);
              const month = dica.date.substring(5, 7);
              const day = dica.date.substring(8, 10);

              return (
                <div className="card" key={dica.id}>
                  <div className="imagem">
                    <img
                      src={dica._embedded['wp:featuredmedia'][0].source_url}
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="date">
                      {day}/{month}/{year}
                    </p>
                    <p className="title">{dica.title.rendered}</p>

                    {ReactHtmlParser(dica.excerpt.rendered)}

                    <a className="mais" href={`/blog/${dica.slug}`}>
                      CONTINUAR LENDO
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        {loading ? '' : loader && <div className="circle"></div>}
        <button
          className={loading ? 'nodisplay' : ''}
          type="button"
          onClick={() => {
            setNumerodeposts(numerodeposts + 6);
            setLoader(true);
          }}
        >
          CARREGAR MAIS
        </button>
        <div id="bottom-footer">
          <p id="rights">GMAD © 2020 / Todos os direitos reservados</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://agenciaade.com.br/"
          >
            <p>
              Criado com{' '}
              <img src={require('../../assets/heart.svg')} alt="Coração" /> por
              agenciaade.com.br
            </p>
          </a>
        </div>
      </Content>
    </>
  );
}
