import React, { useState } from 'react';
import Whatsapp from '../Whatsapp';

//styles
import { Navbar } from './style';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Whatsapp />
      <Navbar isOpen={isMenuOpen}>
        <a href="/gmadplus">
          <img src={require('../../assets/logo.svg')} alt="Logo G+" />
        </a>
        <div>
          <div className="btn-container">
            <div className="btn">
              <a href="/gmadplus#tenhointeresse">TENHO INTERESSE</a>
            </div>
            <div className="btn-secondary">
              <a href="/gmadpay">APP GMADPAY</a>
            </div>
          </div>
          <button
            className={`
            hamburger hamburger--collapse
            ${isMenuOpen ? 'is-active' : ''}
          `}
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <nav>
            <ul>
              <li>
                <a href="/gmadplus">
                  <img src={require('../../assets/logo.svg')} alt="Logo G+" />
                </a>
              </li>
              <li>
                <a
                  href="/gmadplus#porqueaderir"
                  onClick={() => setIsMenuOpen(false)}
                >
                  POR QUE ADERIR
                </a>
              </li>
              <li>
                <a
                  href="/gmadplus#comofunciona"
                  onClick={() => setIsMenuOpen(false)}
                >
                  COMO FUNCIONA
                </a>
              </li>
              <li>
                <a
                  href="/gmadplus#credenciamento"
                  onClick={() => setIsMenuOpen(false)}
                >
                  CREDENCIAMENTO
                </a>
              </li>
              {/* <li>
              <a href="/gmadplus#dicas" onClick={() => setIsMenuOpen(false)}>
                DICAS
              </a>
            </li> */}
              {/* <li>
                <a
                  href="/gmadplus#simuladordetaxas"
                  onClick={() => setIsMenuOpen(false)}
                >
                  SIMULADOR DE TAXAS
                </a>
              </li> */}
              <li>
                <a
                  href="/gmadplus#perguntasfrequentes"
                  onClick={() => setIsMenuOpen(false)}
                >
                  PERGUNTAS FREQUENTES
                </a>
              </li>
              <li>
                <a
                  href="/gmadplus#tenhointeresse"
                  className="interesse"
                  onClick={() => setIsMenuOpen(false)}
                >
                  TENHO INTERESSE
                </a>
              </li>
              <li>
                <a
                  href="/gmadpay"
                  className="gmadpay"
                  onClick={() => setIsMenuOpen(false)}
                >
                  APP GMADPAY
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Navbar>
    </>
  );
}
